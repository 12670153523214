import React from 'react'
import { Link } from "gatsby"

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

class MenuButton extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <Button
          aria-label="search"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
        <Typography component="div"  variant="button" display="block"  style={{ textDecoration: "none", outline: 'none', color: 'black' }}>
            Alarme
        </Typography>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <Link to="/Motion_Detection/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}><strong>Détection de mouvement</strong></MenuItem>
          </Link>
          <hr />
          <Link to="/Motion_Detection/Setup/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Installer</MenuItem>
          </Link>
          <Link to="/Motion_Detection/Setup/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série 1080p</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Setup/720p_Series/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série 720p</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Setup/VGA_Series/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série VGA</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_Notification/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Notification d&apos;alarme</MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_Notification/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série 1080p</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_Notification/720p_Series/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série 720p</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_Notification/VGA_Series/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série VGA</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_Notification/Troubleshooting/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Dépannage</small></MenuItem>
          </Link>
          {/* <Link to="/Motion_Detection/Alarm_Recording/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Enregistrement video</MenuItem>
          </Link> */}
          <Link to="/Motion_Detection/SD_Card_Access/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Accès à la carte SD</MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_FTP_Upload/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Alarme FTP Télécharger</MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_FTP_Upload/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série 1080p</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_FTP_Upload/720p_Series/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série 720p</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_FTP_Upload/VGA_Series/"
            style={{ textDecoration: "none", outline: 'none', }}>
            <MenuItem onClick={this.handleClose}><small>▪ Série VGA</small></MenuItem>
          </Link>
          <Link to="/Motion_Detection/Router_as_a_FTP_Server/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Routeur en tant que serveur FTP</MenuItem>
          </Link>
          <Link to="/Motion_Detection/FTP_Server_Setup/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Configuration du serveur FTP</MenuItem>
          </Link>
          <Link to="/Motion_Detection/Alarm_Server/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>Serveur d&apos;alarme</MenuItem>
          </Link>
          <Link to="/Motion_Detection/INSTAR_Cloud/" style={{ textDecoration: "none", outline: 'none' }}>
            <MenuItem onClick={this.handleClose}>INSTAR Cloud</MenuItem>
          </Link>
        </Menu>
      </div>
    );
  }
}

export default MenuButton
