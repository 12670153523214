import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareWin = React.forwardRef((props, ref) => <Link to="/Software/Windows/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetro = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetroDDNS = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/DDNS/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetroP2P = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/P2P/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetroMulti = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/Multiview/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetroAdvanced = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/Advanced/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetroRec = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/Recording/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetroSettings = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/Settings/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWP = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWPDDNS = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWPP2P = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWPMulti = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/Multiview/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWPRec = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/Recording/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWPSettings = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/Settings/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2 = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        InstarVision UWP App
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWin}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          ▪ Windows
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareWinIVMetro}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for Windows Metro
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetroDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - DDNS Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetroP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - P2P Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetroMulti}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Multiview Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetroAdvanced}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Advanced Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetroRec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetroSettings}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Settings Menu
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareWinIVWP}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for Windows Phone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWPDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - DDNS Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWPP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - P2P Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWPMulti}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Multiview Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWPRec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWPSettings}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Settings Menu
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareWinIV2}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision v2
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)