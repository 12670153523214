import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Downloads = React.forwardRef((props, ref) => <Link to="/Downloads/"  role="button" {...props} ref={ref} />)
const Security = React.forwardRef((props, ref) => <Link to="/Downloads/Security/"  role="button" {...props} ref={ref} />)
const Accessories = React.forwardRef((props, ref) => <Link to="/Downloads/Accessories/"  role="button" {...props} ref={ref} />)
const AccessoriesLight = React.forwardRef((props, ref) => <Link to="/Downloads/Accessories/IN-90x/"  role="button" {...props} ref={ref} />)
const AccessoriesActionCam = React.forwardRef((props, ref) => <Link to="/Downloads/Accessories/IN-DV1215/"  role="button" {...props} ref={ref} />)
const AccessoriesMikro = React.forwardRef((props, ref) => <Link to="/Downloads/Accessories/IN-Mikro_380/"  role="button" {...props} ref={ref} />)
const AccessoriesMotion300 = React.forwardRef((props, ref) => <Link to="/Downloads/Accessories/IN-Motion_300/"  role="button" {...props} ref={ref} />)
const AccessoriesMotion500 = React.forwardRef((props, ref) => <Link to="/Downloads/Accessories/IN-Motion_500/"  role="button" {...props} ref={ref} />)
const Software = React.forwardRef((props, ref) => <Link to="/Downloads/Desktop_Software/"  role="button" {...props} ref={ref} />)
const SoftwareCamTool = React.forwardRef((props, ref) => <Link to="/Downloads/Desktop_Software/Instar_Camera_Tool/"  role="button" {...props} ref={ref} />)
const SoftwareInstarVision = React.forwardRef((props, ref) => <Link to="/Downloads/Desktop_Software/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareInstarVisionApple = React.forwardRef((props, ref) => <Link to="/Downloads/Desktop_Software/InstarVision_for_macOS/"  role="button" {...props} ref={ref} />)
const SoftwareMetro = React.forwardRef((props, ref) => <Link to="/Downloads/Desktop_Software/InstarVision_Metro/"  role="button" {...props} ref={ref} />)
const IndoorCams = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/"  role="button" {...props} ref={ref} />)
const IndoorCams3001 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-3001/"  role="button" {...props} ref={ref} />)
const IndoorCams3003 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-3003/"  role="button" {...props} ref={ref} />)
const IndoorCams3010 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-3010/"  role="button" {...props} ref={ref} />)
const IndoorCams3011 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-3011/"  role="button" {...props} ref={ref} />)
const IndoorCams6001 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-6001_HD/"  role="button" {...props} ref={ref} />)
const IndoorCams6012 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-6012_HD/"  role="button" {...props} ref={ref} />)
const IndoorCams6014 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-6014_HD/"  role="button" {...props} ref={ref} />)
const IndoorCams8015 = React.forwardRef((props, ref) => <Link to="/Downloads/Indoor_Cameras/IN-8015_HD/"  role="button" {...props} ref={ref} />)
const OutdoorCams = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/"  role="button" {...props} ref={ref} />)
const OutdoorCams2904 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-2904/"  role="button" {...props} ref={ref} />)
const OutdoorCams2905 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-2905_V2/"  role="button" {...props} ref={ref} />)
const OutdoorCams2908 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-2908/"  role="button" {...props} ref={ref} />)
const OutdoorCams4009 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-4009/"  role="button" {...props} ref={ref} />)
const OutdoorCams4010 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-4010_V2/"  role="button" {...props} ref={ref} />)
const OutdoorCams4011 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-4011/"  role="button" {...props} ref={ref} />)
const OutdoorCams5905 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-5905_HD/"  role="button" {...props} ref={ref} />)
const OutdoorCams5907 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-5907_HD/"  role="button" {...props} ref={ref} />)
const OutdoorCams7011 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-7011_HD/"  role="button" {...props} ref={ref} />)
const OutdoorCams9008 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-9008_HD/"  role="button" {...props} ref={ref} />)
const OutdoorCams9010 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-9010_HD/"  role="button" {...props} ref={ref} />)
const OutdoorCams9020 = React.forwardRef((props, ref) => <Link to="/Downloads/Outdoor_Cameras/IN-9020_HD/"  role="button" {...props} ref={ref} />)
const MobileApps = React.forwardRef((props, ref) => <Link to="/Downloads/Mobile_Apps/"  role="button" {...props} ref={ref} />)
const MobileAppsAndroid = React.forwardRef((props, ref) => <Link to="/Downloads/Mobile_Apps/Android_BlackBerry/"  role="button" {...props} ref={ref} />)
const MobileAppsiOS = React.forwardRef((props, ref) => <Link to="/Downloads/Mobile_Apps/iOS/"  role="button" {...props} ref={ref} />)
const MobileAppsWP = React.forwardRef((props, ref) => <Link to="/Downloads/Mobile_Apps/Windows_Phone/"  role="button" {...props} ref={ref} />)
const NetworkAccessories = React.forwardRef((props, ref) => <Link to="/Downloads/Network_Accessories/"  role="button" {...props} ref={ref} />)
const NetworkAccessoriesLAN = React.forwardRef((props, ref) => <Link to="/Downloads/Network_Accessories/IN-LAN_500/"  role="button" {...props} ref={ref} />)
const NetworkAccessoriesPoE = React.forwardRef((props, ref) => <Link to="/Downloads/Network_Accessories/IN-PoE_1000/"  role="button" {...props} ref={ref} />)
const NetworkAccessoriesRoute = React.forwardRef((props, ref) => <Link to="/Downloads/Network_Accessories/IN-Route_P52/"  role="button" {...props} ref={ref} />)


function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Des produits
        </Typography>
      <ListItem button component={Downloads}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Téléchargements
          </Typography>
      </ListItem>
      <ListItem button component={Security}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ What is SHA265?
          </Typography>
      </ListItem>

      <ListItem button component={IndoorCams}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Caméras d&apos;intérieur
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams3001}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-3001
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams3003}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-3003
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams3010}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-3010
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams3011}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-3011
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams6001}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-6001 HD
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams6012}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-6012 HD
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams6014}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-6014 HD
          </Typography>
      </ListItem>
      <ListItem button component={IndoorCams8015}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-8015 Full HD
          </Typography>
      </ListItem>

      <ListItem button component={OutdoorCams}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Caméras d&apos;extérieur
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams2904}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-2904
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams2905}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-2905
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams2908}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-2908
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams4009}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-4009
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams4010}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-4010
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams4011}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-4011
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams5905}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-5905 HD
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams5907}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-5907 HD
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams7011}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-7011 HD
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams9008}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-9008 Full HD
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams9010}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-9010 Full HD
          </Typography>
      </ListItem>
      <ListItem button component={OutdoorCams9020}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-9020 Full HD
          </Typography>
      </ListItem>

      <ListItem button component={Software}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Logiciel
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareCamTool}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision Camera Tool
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareInstarVision}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision Windows
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareInstarVisionApple}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision macOS
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareMetro}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision Metro
          </Typography>
      </ListItem>

      <ListItem button component={MobileApps}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Mobile Apps
          </Typography>
      </ListItem>
      <ListItem button component={MobileAppsAndroid}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision Android & BlackBerry
          </Typography>
      </ListItem>
      <ListItem button component={MobileAppsiOS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision iOS
          </Typography>
      </ListItem>
      <ListItem button component={MobileAppsWP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision Windows Phone
          </Typography>
      </ListItem>

      <ListItem button component={Accessories}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Accessories
          </Typography>
      </ListItem>
      <ListItem button component={AccessoriesLight}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Projecteurs infrarouges
          </Typography>
      </ListItem>
      <ListItem button component={AccessoriesActionCam}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-DV1215
          </Typography>
      </ListItem>
      <ListItem button component={AccessoriesMikro}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-Mikro 380
          </Typography>
      </ListItem>
      <ListItem button component={AccessoriesMotion300}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-Motion 300
          </Typography>
      </ListItem>
      <ListItem button component={AccessoriesMotion500}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          ›IN-Motion 500
          </Typography>
      </ListItem>

      <ListItem button component={NetworkAccessories}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Network Accessories
          </Typography>
      </ListItem>
      <ListItem button component={NetworkAccessoriesLAN}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-LAN 500
          </Typography>
      </ListItem>
      <ListItem button component={NetworkAccessoriesPoE}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-PoE 1000
          </Typography>
      </ListItem>
      <ListItem button component={NetworkAccessoriesRoute}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IN-Route P52
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)