import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const IndoorCams = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/"  role="button" {...props} ref={ref} />)
const In6012 = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/"  role="button" {...props} ref={ref} />)
const In6012Manual = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Usermanual/"  role="button" {...props} ref={ref} />)
const In6012QS = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Quick_Installation/"  role="button" {...props} ref={ref} />)
const In6012Warning = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Safety_Warnings/"  role="button" {...props} ref={ref} />)
const In6012Features = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Product_Features/"  role="button" {...props} ref={ref} />)
const In6012Specs = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Technical_Specifications/"  role="button" {...props} ref={ref} />)
const In6012Reset = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Camera_Reset/"  role="button" {...props} ref={ref} />)
const In6012Lense = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Lense_Adjustment/"  role="button" {...props} ref={ref} />)
const In6012Warranty = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Warranty/"  role="button" {...props} ref={ref} />)
const In6012Streaming = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-6012_HD/Video_Streaming/"  role="button" {...props} ref={ref} />)


function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>
      <ListItem button component={IndoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Indoor Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In6012}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-6012 HD
          </Typography>
      </ListItem>
      <ListItem button component={In6012Manual}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Usermanual
          </Typography>
      </ListItem>
      <ListItem button component={In6012QS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Installation
          </Typography>
      </ListItem>
      <ListItem button component={In6012Warning}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Safety Warnings
          </Typography>
      </ListItem>
      <ListItem button component={In6012Features}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Product Features
          </Typography>
      </ListItem>
      <ListItem button component={In6012Specs}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Technical Specifications
          </Typography>
      </ListItem>
      <ListItem button component={In6012Reset}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Reset
          </Typography>
      </ListItem>
      <ListItem button component={In6012Lense}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Lense Adjustment
          </Typography>
      </ListItem>
      <ListItem button component={In6012Streaming}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video Streaming
          </Typography>
      </ListItem>
      <ListItem button component={In6012Warranty}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Warranty & Disposal
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)