import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const IndoorCams = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/"  role="button" {...props} ref={ref} />)
const In3011 = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-3011/"  role="button" {...props} ref={ref} />)
const In3011Warning = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-3011/Safety_Warnings/"  role="button" {...props} ref={ref} />)
const In3011Specs = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-3011/Technical_Specifications/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>
      <ListItem button component={IndoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Indoor Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In3011}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-3011
          </Typography>
      </ListItem>
      <ListItem button component={In3011Specs}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Technical Specifications
          </Typography>
      </ListItem>
      <ListItem button component={In3011Warning}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Safety Warnings
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)