import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)

const SoftwareWin = React.forwardRef((props, ref) => <Link to="/Software/Windows/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2 = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWP = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetro = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/"  role="button" {...props} ref={ref} />)
const SoftwareWinBlueIris = React.forwardRef((props, ref) => <Link to="/Software/Windows/Blue_Iris/"  role="button" {...props} ref={ref} />)
const SoftwareWinBlueIris5 = React.forwardRef((props, ref) => <Link to="/Software/Windows/Blue_Iris_v5/"  role="button" {...props} ref={ref} />)
const SoftwareWinCCTV = React.forwardRef((props, ref) => <Link to="/Software/Windows/CCTV_Chrome_Plugin/"  role="button" {...props} ref={ref} />)
const SoftwareWingo1984 = React.forwardRef((props, ref) => <Link to="/Software/Windows/go1984/"  role="button" {...props} ref={ref} />)
const SoftwareWiniSpy = React.forwardRef((props, ref) => <Link to="/Software/Windows/iSpy/"  role="button" {...props} ref={ref} />)
const SoftwareWinP2P = React.forwardRef((props, ref) => <Link to="/Software/Windows/P2P_Client/"  role="button" {...props} ref={ref} />)
const SoftwareWinSighthound = React.forwardRef((props, ref) => <Link to="/Software/Windows/Sighthound/"  role="button" {...props} ref={ref} />)
const SoftwareWinVLC = React.forwardRef((props, ref) => <Link to="/Software/Windows/VLC_Player/"  role="button" {...props} ref={ref} />)
const SoftwareWinWebcam = React.forwardRef((props, ref) => <Link to="/Software/Windows/Webcam_Motion_Detector/"  role="button" {...props} ref={ref} />)
const SoftwareWinWebCamXP = React.forwardRef((props, ref) => <Link to="/Software/Windows/WebCam_XP/"  role="button" {...props} ref={ref} />)
const SoftwareWinYAW = React.forwardRef((props, ref) => <Link to="/Software/Windows/YAW_Cam/"  role="button" {...props} ref={ref} />)
const SoftwareWinZebra = React.forwardRef((props, ref) => <Link to="/Software/Windows/Zebra_Surveillance/"  role="button" {...props} ref={ref} />)
const SoftwareWinZone = React.forwardRef((props, ref) => <Link to="/Software/Windows/Zone_Trigger/"  role="button" {...props} ref={ref} />)

const SoftwareMacOS = React.forwardRef((props, ref) => <Link to="/Software/macOS/"  role="button" {...props} ref={ref} />)
const SoftwareMacOSEvoCam = React.forwardRef((props, ref) => <Link to="/Software/macOS/EvoCam/"  role="button" {...props} ref={ref} />)
const SoftwareMacOSSecuritySpy = React.forwardRef((props, ref) => <Link to="/Software/macOS/SecuritySpy/"  role="button" {...props} ref={ref} />)
const SoftwareMacOSSighthound = React.forwardRef((props, ref) => <Link to="/Software/macOS/Sighthound/"  role="button" {...props} ref={ref} />)
const SoftwareMacOSVLC = React.forwardRef((props, ref) => <Link to="/Software/macOS/VLC_Player/"  role="button" {...props} ref={ref} />)

const SoftwareLinux = React.forwardRef((props, ref) => <Link to="/Software/Linux/"  role="button" {...props} ref={ref} />)
const SoftwareLinuxMotionEye = React.forwardRef((props, ref) => <Link to="/Software/Linux/MotionEye/"  role="button" {...props} ref={ref} />)
const SoftwareLinuxShinobi = React.forwardRef((props, ref) => <Link to="/Software/Linux/Shinobi_Open_Source_CCTV/"  role="button" {...props} ref={ref} />)
const SoftwareLinuxNodeRED = React.forwardRef((props, ref) => <Link to="/Software/Linux/Node-RED/"  role="button" {...props} ref={ref} />)
const SoftwareLinuxHomeAss = React.forwardRef((props, ref) => <Link to="/Software/Linux/Home_Assistant/"  role="button" {...props} ref={ref} />)
const SoftwareLinuxOpenHAB2 = React.forwardRef((props, ref) => <Link to="/Software/Linux/OpenHAB2/"  role="button" {...props} ref={ref} />)

const SoftwareAnd = React.forwardRef((props, ref) => <Link to="/Software/Android/"  role="button" {...props} ref={ref} />)
const SoftwareAndIV = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareAndIPCam = React.forwardRef((props, ref) => <Link to="/Software/Android/IP_Cam_Viewer/"  role="button" {...props} ref={ref} />)
const SoftwareAndTiny = React.forwardRef((props, ref) => <Link to="/Software/Android/TinyCam_Monitor/"  role="button" {...props} ref={ref} />)

const SoftwareIOs = React.forwardRef((props, ref) => <Link to="/Software/iOS/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIV = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIPCam = React.forwardRef((props, ref) => <Link to="/Software/iOS/IP_Cam_Viewer/"  role="button" {...props} ref={ref} />)
const SoftwareIOsLiveCams = React.forwardRef((props, ref) => <Link to="/Software/iOS/LiveCams/"  role="button" {...props} ref={ref} />)
const SoftwareIOsICCAM = React.forwardRef((props, ref) => <Link to="/Software/iOS/ICCAM/"  role="button" {...props} ref={ref} />)
const SoftwareIOsVision = React.forwardRef((props, ref) => <Link to="/Software/iOS/IP_Vision_Pro/"  role="button" {...props} ref={ref} />)
const SoftwareIOsP2P = React.forwardRef((props, ref) => <Link to="/Software/iOS/P2P_Cam_Live/"  role="button" {...props} ref={ref} />)

const SoftwareHome = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHomee = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Homee/"  role="button" {...props} ref={ref} />)
const SoftwareHomeLox = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Loxone/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHomematic = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Homematic/"  role="button" {...props} ref={ref} />)
const SoftwareHomeFHEM = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/FHEM/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHass = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Home_Assistant/"  role="button" {...props} ref={ref} />)
const SoftwareHomeIO = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/IOBroker/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHab = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/OpenHAB2/"  role="button" {...props} ref={ref} />)
const SoftwareHomeRed = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Node-RED/"  role="button" {...props} ref={ref} />)

const SoftwareOther = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/"  role="button" {...props} ref={ref} />)
const SoftwareOtherInstarVision = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/InstarVision_Blackberry/"  role="button" {...props} ref={ref} />)
const SoftwareOtherSynology = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Synology/"  role="button" {...props} ref={ref} />)
const SoftwareOtherQNAP = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/QNAP/"  role="button" {...props} ref={ref} />)
const SoftwareOtherFritzphone = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/AVM_Fritzphone/"  role="button" {...props} ref={ref} />)
const SoftwareOtherHomematic = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Homematic_IP/"  role="button" {...props} ref={ref} />)
const SoftwareOtherLoxone = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Loxone_Miniserver/"  role="button" {...props} ref={ref} />)
const SoftwareOtherHomee = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Homee/"  role="button" {...props} ref={ref} />)
const SoftwareOtherDomovea = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Domovea/"  role="button" {...props} ref={ref} />)
const SoftwareOtherASUSTOR = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/ASUSTOR_Surveillance_Center/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
    <Typography variant="h5" className={classes.navtitle}>
    Des produits
      </Typography>
    <ListItem button component={Software}>
      <Typography variant="subtitle1" className={classes.navsubtitle}>
      Logiciel
        </Typography>
    </ListItem>


    <ListItem button component={SoftwareWin}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Windows
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinIV2}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › InstarVision v2
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinIVWP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › InstarVision for Windows Phone
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinIVMetro}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › InstarVision for Windows Metro
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinBlueIris}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Blue Iris
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinCCTV}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › CCTV Browser Plugin
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWingo1984}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › go1984
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWiniSpy}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › iSpy
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinP2P}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › P2P Client
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinSighthound}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Sighthound
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinVLC}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › VLC Player
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinWebcam}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Webcam Motion Detector
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinWebCamXP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › WebCam XP
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinYAW}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › YAW Cam
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinZebra}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Zebra Surveillance
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareWinZone}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Zone Trigger
        </Typography>
    </ListItem>


    <ListItem button component={SoftwareMacOS}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ macOS
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareMacOSSighthound}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Sighthound
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareMacOSSecuritySpy}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › SecuritySpy
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareMacOSVLC}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › VLC Player
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareMacOSEvoCam}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › EvoCam
        </Typography>
    </ListItem>


    <ListItem button component={SoftwareLinux}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Linux
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareLinuxMotionEye}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › MotionEye
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareLinuxNodeRED}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Node-RED & MQTT
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareLinuxOpenHAB2}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › OpenHAB 2
        </Typography>
    </ListItem>


    <ListItem button component={SoftwareAnd}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Android
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareAndIV}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › InstarVision for Android
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareAndIPCam}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › IP Cam Viewer
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareAndTiny}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › TinyCam Monitor
        </Typography>
    </ListItem>

    <ListItem button component={SoftwareIOs}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ iOS
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareIOsIV}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › InstarVision for iPhone & iPad
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareIOsIPCam}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › IP Cam Viewer
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareIOsLiveCams}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › LiveCams
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareIOsICCAM}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › ICCAM
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareIOsVision}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › IP Vision Pro
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareIOsP2P}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › P2P Cam Live
        </Typography>
    </ListItem>


    <ListItem button component={SoftwareOther}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Other Platforms
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareOtherInstarVision}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › InstarVision for Blackberry
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareOtherSynology}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Synology
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareOtherQNAP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › QNAP
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareOtherFritzphone}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › AVM Fritzphone
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareOtherDomovea}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Domovea
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareOtherASUSTOR}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › ASUSTOR Surveillance Center
        </Typography>
    </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)