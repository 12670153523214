import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'

import { withStyles } from '@material-ui/core/styles'

import NavHeader from './nav-header'
import ChapterNav from './nav-chapter'
import ProductNav from './nav-products'
import IndoorNav from './nav-indoor-cameras'
import IndoorNav3011 from './nav-indoor-cameras-3011'
import IndoorNav6001 from './nav-indoor-cameras-6001'
import IndoorNav6012 from './nav-indoor-cameras-6012'
import IndoorNav6014 from './nav-indoor-cameras-6014'
import IndoorNav8001 from './nav-indoor-cameras-8001'
import IndoorNav8003 from './nav-indoor-cameras-8003'
import IndoorNav8015 from './nav-indoor-cameras-8015'
import OutdoorNav from './nav-outdoor-cameras'
import OutdoorNav2905 from './nav-outdoor-cameras-2905'
import OutdoorNav2908 from './nav-outdoor-cameras-2908'
import OutdoorNav4010 from './nav-outdoor-cameras-4010'
import OutdoorNav4011 from './nav-outdoor-cameras-4011'
import OutdoorNav5905 from './nav-outdoor-cameras-5905'
import OutdoorNav5907 from './nav-outdoor-cameras-5907'
import OutdoorNav7011 from './nav-outdoor-cameras-7011'
import OutdoorNav9008 from './nav-outdoor-cameras-9008'
import OutdoorNav9010 from './nav-outdoor-cameras-9010'
import OutdoorNav9020 from './nav-outdoor-cameras-9020'
import SoftwareNavWin from './nav-software-windows'
import SoftwareNavWinIV from './nav-software-windows-iv'
import SoftwareNavWinIVWin from './nav-software-windows-iv-win'
import SoftwareNavMacOS from './nav-software-macOS'
import SoftwareNavLinux from './nav-software-linux'
import SoftwareNavAndroid from './nav-software-android'
import SoftwareNavAndroidIV from './nav-software-android-iv'
import SoftwareNavIos from './nav-software-ios'
import SoftwareNavIosIV from './nav-software-ios-iv'
import SoftwareNavSmartHome from './nav-software-smarthome'
import SoftwareNavOther from './nav-software-other'
import SoftwareNav from './nav-software'
import CgiHDNav from './nav-720p-cgis'
import CgiFHDNav from './nav-1080p-cgis'
import DownloadsNav from './nav-downloads'
import InstallationNav from './nav-installation'
import MotionNav from './nav-motion'
import InternetNav from './nav-internet'
import AdvancedNav from './nav-advanced'
import WebUiHDNav from './nav-webui720p'
import WebUiFHDNav from './nav-webui1080p'

import Conditional from './conditional-render'

const styles = {
  root: {
    width: 300,
  }
}

function MainNav(props) {
  const { classes } = props
  return (
    <Location>
      {({ location }) => (
        <div className={classes.root}>
          <NavHeader />
          <ChapterNav />
          <Conditional if={location.pathname.match(/^\/en\/Products\//i)}>
            <ProductNav />
          </Conditional>
          <Conditional if={location.pathname === "/en/Indoor_Cameras/"}>
            <IndoorNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-8015_HD\//i)}>
            <IndoorNav8015 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-8003_HD\//i)}>
            <IndoorNav8003 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-8001_HD\//i)}>
            <IndoorNav8001 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-6014_HD\//i)}>
            <IndoorNav6014 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-6012_HD\//i)}>
            <IndoorNav6012 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-6001_HD\//i)}>
            <IndoorNav6001 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Indoor_Cameras\/IN-3011\//i)}>
            <IndoorNav3011 />
          </Conditional>
          <Conditional if={location.pathname === "/en/Outdoor_Cameras/"}>
            <OutdoorNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-9020_HD\//i)}>
            <OutdoorNav9020 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-9010_HD\//i)}>
            <OutdoorNav9010 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-9008_HD\//i)}>
            <OutdoorNav9008 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-7011_HD\//i)}>
            <OutdoorNav7011 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-5907_HD\//i)}>
            <OutdoorNav5907 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-5905_HD\//i)}>
            <OutdoorNav5905 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-4011\//i)}>
            <OutdoorNav4011 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-4010_V2\//i)}>
            <OutdoorNav4010 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-2908\//i)}>
            <OutdoorNav2908 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Outdoor_Cameras\/IN-2905\//i)}>
            <OutdoorNav2905 />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Downloads\//i)}>
            <DownloadsNav />
          </Conditional>
          <Conditional if={location.pathname === "/en/Software/"}>
            <SoftwareNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Windows\/InstarVision\/Windows_Phone\//i)}>
            <SoftwareNavWinIV />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Windows\/InstarVision\/Metro\//i)}>
            <SoftwareNavWinIV />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Windows\/InstarVision\//i)}>
            <SoftwareNavWinIVWin />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Windows\//i)}>
            <SoftwareNavWin />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/macOS\//i)}>
            <SoftwareNavMacOS />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Linux\//i)}>
            <SoftwareNavLinux />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Android\/InstarVision\//i)}>
            <SoftwareNavAndroidIV />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Android\//i)}>
            <SoftwareNavAndroid />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/iOS\/InstarVision\//i)}>
            <SoftwareNavIosIV />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/iOS\//i)}>
            <SoftwareNavIos />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Home_Automation\//i)}>
            <SoftwareNavSmartHome />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Software\/Other_Platforms\//i)}>
            <SoftwareNavOther />
          </Conditional>
          <Conditional if={location.pathname === "/en/Web_User_Interface/1080p_Series/Overview/"}>
            <ProductNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Web_User_Interface\/720p_Series\//i)}>
            <WebUiHDNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Web_User_Interface\/1080p_Series\//i)}>
            <WebUiFHDNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Quick_Installation\//i)}>
            <InstallationNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Motion_Detection\//i)}>
            <MotionNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Internet_Access\//i)}>
            <InternetNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/Advanced_User\//i)}>
            <AdvancedNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/720p_Series_CGI_List\//i)}>
            <CgiHDNav />
          </Conditional>
          <Conditional if={location.pathname.match(/^\/en\/1080p_Series_CGI_List\//i)}>
            <CgiFHDNav />
          </Conditional>
        </div >
      )}
    </Location>
  );
}

MainNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(MainNav)