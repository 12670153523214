import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import SearchIcon from '@material-ui/icons/Search'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloudDownload from '@material-ui/icons/CloudDownload'
import LocalLibrary from '@material-ui/icons/LocalLibrary'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import ForumButton from '@material-ui/icons/Forum';
import ShoppingCartButton from '@material-ui/icons/ShoppingCart'
import AnnouncementButton from '@material-ui/icons/Announcement'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const ButtonLink00 = React.forwardRef((props, ref) => <Link to="/"  role="button" {...props} ref={ref} />)
const ButtonLink01 = React.forwardRef((props, ref) => <Link to="/Search/"  role="button" {...props} ref={ref} />)
const ButtonLink02 = React.forwardRef((props, ref) => <Link to="/FAQs/"  role="button" {...props} ref={ref} />)
const ButtonLink03 = React.forwardRef((props, ref) => <Link to="/Downloads/"  role="button" {...props} ref={ref} />)
const ButtonLink04 = React.forwardRef((props, ref) => <Link to="/Products/Usermanuals/"  role="button" {...props} ref={ref} />)
const ButtonLink05 = React.forwardRef((props, ref) => <Link to="/Assistants/Review_Wall/"  role="button" {...props} ref={ref} />)

function NavHeader(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Support
        </Typography>
      <ListItem button component={ButtonLink00}>
        <ListItemIcon>
          <HomeOutlinedIcon className={classes.navbutton} />
        </ListItemIcon>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Accueil
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink01}>
        <ListItemIcon>
          <SearchIcon className={classes.navbutton} />
        </ListItemIcon>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Recherchez
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink02}>
        <ListItemIcon>
          <HelpOutlineIcon className={classes.navbutton} />
        </ListItemIcon>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          FAQs
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink03}>
        <ListItemIcon>
          <CloudDownload className={classes.navbutton} />
        </ListItemIcon>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Téléchargements
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink04}>
        <ListItemIcon>
          <LocalLibrary className={classes.navbutton} />
        </ListItemIcon>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Manuels d&apos;utilisation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink05}>
        <ListItemIcon>
          <SubscriptionsIcon className={classes.navbutton} />
        </ListItemIcon>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Témoignages
        </Typography>
      </ListItem>
      <OutboundLink href="https://forum.instar.de/" target="_blank" rel="noopener noreferrer">
        <ListItem button>
          <ListItemIcon>
            <ForumButton className={classes.navbutton} />
          </ListItemIcon>
          <Typography variant="subtitle1" className={classes.navsubtitle}>
            Forum des clients
          </Typography>
        </ListItem>
      </OutboundLink>
      <OutboundLink href="https://www.instar.de/?___store=de_english&___from_store=de_german" target="_blank" rel="noopener noreferrer">
        <ListItem button>
          <ListItemIcon>
            <ShoppingCartButton className={classes.navbutton} />
          </ListItemIcon>
          <Typography variant="subtitle1" className={classes.navsubtitle}>
            Boutique en Ligne
          </Typography>
        </ListItem>
      </OutboundLink>
      <OutboundLink href="https://www.instar.de/privacy?___store=de_english&___from_store=de_german" target="_blank" rel="noopener noreferrer">
        <ListItem button>
          <ListItemIcon>
            <AnnouncementButton className={classes.navbutton} />
          </ListItemIcon>
          <Typography variant="subtitle1" className={classes.navsubtitle}>
            Vie privée
          </Typography>
        </ListItem>
      </OutboundLink>
    </List>
  );
}

NavHeader.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles, { withTheme: true })(NavHeader)