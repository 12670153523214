import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareWin = React.forwardRef((props, ref) => <Link to="/Software/Windows/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2 = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWP = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetro = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/"  role="button" {...props} ref={ref} />)
const SoftwareWinBlueIris = React.forwardRef((props, ref) => <Link to="/Software/Windows/Blue_Iris/"  role="button" {...props} ref={ref} />)
const SoftwareWinBlueIris5 = React.forwardRef((props, ref) => <Link to="/Software/Windows/Blue_Iris_v5/"  role="button" {...props} ref={ref} />)
const SoftwareWinBlueIris5MQTT = React.forwardRef((props, ref) => <Link to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/"  role="button" {...props} ref={ref} />)
const SoftwareWinCCTV = React.forwardRef((props, ref) => <Link to="/Software/Windows/CCTV_Chrome_Plugin/"  role="button" {...props} ref={ref} />)
const SoftwareWingo1984 = React.forwardRef((props, ref) => <Link to="/Software/Windows/go1984/"  role="button" {...props} ref={ref} />)
const SoftwareWiniSpy = React.forwardRef((props, ref) => <Link to="/Software/Windows/iSpy/"  role="button" {...props} ref={ref} />)
const SoftwareWinP2P = React.forwardRef((props, ref) => <Link to="/Software/Windows/P2P_Client/"  role="button" {...props} ref={ref} />)
const SoftwareWinSighthound = React.forwardRef((props, ref) => <Link to="/Software/Windows/Sighthound/"  role="button" {...props} ref={ref} />)
const SoftwareWinVLC = React.forwardRef((props, ref) => <Link to="/Software/Windows/VLC_Player/"  role="button" {...props} ref={ref} />)
const SoftwareWinWebcam = React.forwardRef((props, ref) => <Link to="/Software/Windows/Webcam_Motion_Detector/"  role="button" {...props} ref={ref} />)
const SoftwareWinWebCamXP = React.forwardRef((props, ref) => <Link to="/Software/Windows/WebCam_XP/"  role="button" {...props} ref={ref} />)
const SoftwareWinYAW = React.forwardRef((props, ref) => <Link to="/Software/Windows/YAW_Cam/"  role="button" {...props} ref={ref} />)
const SoftwareWinZebra = React.forwardRef((props, ref) => <Link to="/Software/Windows/Zebra_Surveillance/"  role="button" {...props} ref={ref} />)
const SoftwareWinZone = React.forwardRef((props, ref) => <Link to="/Software/Windows/Zone_Trigger/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Windows Software
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>


      <ListItem button component={SoftwareWin}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Windows
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision v2
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision für Windows Phone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetro}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision für Windows Metro
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinBlueIris5}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Blue Iris v5
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinBlueIris5MQTT}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Blue Iris MQTT
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinBlueIris}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Blue Iris v3
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinCCTV}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › CCTV Browser Plugin
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWingo1984}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › go1984
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWiniSpy}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › iSpy
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › P2P Client
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinSighthound}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Sighthound
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinVLC}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › VLC Player
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinWebcam}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Webcam Motion Detector
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinWebCamXP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › WebCam XP
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinYAW}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › YAW Cam
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinZebra}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Zebra Surveillance
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinZone}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Zone Trigger
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)