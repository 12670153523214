import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const IndoorCams = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/"  role="button" {...props} ref={ref} />)
const In8015 = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/"  role="button" {...props} ref={ref} />)
const In8015Manual = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Usermanual/"  role="button" {...props} ref={ref} />)
const In8015QS = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Quick_Installation/"  role="button" {...props} ref={ref} />)
const In8015Warning = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Safety_Warnings/"  role="button" {...props} ref={ref} />)
const In8015Features = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/"  role="button" {...props} ref={ref} />)
const In8015Specs = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Technical_Specifications/"  role="button" {...props} ref={ref} />)
const In8015Reset = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Camera_Reset/"  role="button" {...props} ref={ref} />)
const In8015Lense = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Lense_Adjustment/"  role="button" {...props} ref={ref} />)
const In8015Warranty = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Warranty/"  role="button" {...props} ref={ref} />)
const In8015Streaming = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8015_HD/Video_Streaming/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>
      <ListItem button component={IndoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Indoor Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In8015}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-8015 Full HD
          </Typography>
      </ListItem>
      <ListItem button component={In8015Manual}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Usermanual
          </Typography>
      </ListItem>
      <ListItem button component={In8015QS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Installation
          </Typography>
      </ListItem>
      <ListItem button component={In8015Warning}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Safety Warnings
          </Typography>
      </ListItem>
      <ListItem button component={In8015Features}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Product Features
          </Typography>
      </ListItem>
      <ListItem button component={In8015Specs}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Technical Specifications
          </Typography>
      </ListItem>
      <ListItem button component={In8015Reset}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Reset
          </Typography>
      </ListItem>
      <ListItem button component={In8015Lense}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Lense Adjustment
          </Typography>
      </ListItem>
      <ListItem button component={In8015Streaming}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video Streaming
          </Typography>
      </ListItem>
      <ListItem button component={In8015Warranty}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Warranty & Disposal
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles, { withTheme: true })(ProductNav)