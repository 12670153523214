import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareIOs = React.forwardRef((props, ref) => <Link to="/Software/iOS/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIV = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIPCam = React.forwardRef((props, ref) => <Link to="/Software/iOS/IP_Cam_Viewer/"  role="button" {...props} ref={ref} />)
const SoftwareIOsLiveCams = React.forwardRef((props, ref) => <Link to="/Software/iOS/LiveCams/"  role="button" {...props} ref={ref} />)
const SoftwareIOsICCAM = React.forwardRef((props, ref) => <Link to="/Software/iOS/ICCAM/"  role="button" {...props} ref={ref} />)
const SoftwareIOsVision = React.forwardRef((props, ref) => <Link to="/Software/iOS/IP_Vision_Pro/"  role="button" {...props} ref={ref} />)
const SoftwareIOsP2P = React.forwardRef((props, ref) => <Link to="/Software/iOS/P2P_Cam_Live/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        iOS Software
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareIOs}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ iOS
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIV}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision für iPhone & iPad
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIPCam}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IP Cam Viewer
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsLiveCams}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › LiveCams
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsICCAM}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › ICCAM
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsVision}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IP Vision Pro
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › P2P Cam Live
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)