import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareWin = React.forwardRef((props, ref) => <Link to="/Software/Windows/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2 = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2List = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Camera_List/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2Layout = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Layout/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2PTZ = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/PTZ/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2Image = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Image_Adjust/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2Server = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Webserver/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2Rec = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Record/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2System = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/System/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2User = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Users/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2Advanced = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Advanced/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2About = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/About/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWP = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetro = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        InstarVision Windows
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWin}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          ▪ Windows
          </Typography>
      </ListItem>


      <ListItem button component={SoftwareWinIV2}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision v2
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2List}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Camera List
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2Layout}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Layout
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2PTZ}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - PTZ
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2Image}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Image Settings
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2Server}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Webserver
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2Rec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Recordings
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2System}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - System
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2User}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - User Management
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2Advanced}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Advanced
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2About}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - About
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareWinIVWP}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for Windows Phone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetro}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for Windows Metro
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)