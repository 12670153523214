import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const OutdoorCams = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/"  role="button" {...props} ref={ref} />)
const In9020 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/"  role="button" {...props} ref={ref} />)
const In9020Manual = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Usermanual/"  role="button" {...props} ref={ref} />)
const In9020QS = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Quick_Installation/"  role="button" {...props} ref={ref} />)
const In9020Warning = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Safety_Warnings/"  role="button" {...props} ref={ref} />)
const In9020Features = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Product_Features/"  role="button" {...props} ref={ref} />)
const In9020Specs = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Technical_Specifications/"  role="button" {...props} ref={ref} />)
const In9020Reset = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Camera_Reset/"  role="button" {...props} ref={ref} />)
const In9020Lense = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Lense_Adjustment/"  role="button" {...props} ref={ref} />)
const In9020Warranty = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Warranty/"  role="button" {...props} ref={ref} />)
const In9020Streaming = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/Video_Streaming/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>

      <ListItem button component={OutdoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Outdoor Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In9020}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-9020 Full HD
          </Typography>
      </ListItem>
      <ListItem button component={In9020Manual}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Usermanual
          </Typography>
      </ListItem>
      <ListItem button component={In9020QS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Installation
          </Typography>
      </ListItem>
      <ListItem button component={In9020Warning}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Safety Warnings
          </Typography>
      </ListItem>
      <ListItem button component={In9020Features}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Product Features
          </Typography>
      </ListItem>
      <ListItem button component={In9020Specs}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Technical Specifications
          </Typography>
      </ListItem>
      <ListItem button component={In9020Reset}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Reset
          </Typography>
      </ListItem>
      <ListItem button component={In9020Lense}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Lense Adjustment
          </Typography>
      </ListItem>
      <ListItem button component={In9020Streaming}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video Streaming
          </Typography>
      </ListItem>
      <ListItem button component={In9020Warranty}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Warranty & Disposal
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)