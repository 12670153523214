module.exports = {
    mobile: `(min-width: 400px)`,
    Mobile: `@media (min-width: 400px)`,
    phablet: `(min-width: 550px)`,
    Phablet: `@media (min-width: 550px)`,
    tablet: `(min-width: 750px)`,
    Tablet: `@media (min-width: 750px)`,
    desktop: `(min-width: 1000px)`,
    Desktop: `@media (min-width: 1000px)`,
    hd: `(min-width: 1200px)`,
    Hd: `@media (min-width: 1200px)`,
    VHd: `@media (min-width: 1450px)`,
    VVHd: `@media (min-width: 1650px)`,
    maxWidth: 35,
    maxWidthWithSidebar: 30,
    darkGrey: `#212121`,
    grey: `#444444`,
    lightGrey: `#9E9E9E`,
    veryLightGrey: `#F5F5F5`,
    sidebar: `#0275d8`,
    calm: `rgba(38, 32, 44, .5)`,
    brand: `#137bdf`, // "Material Blue"
    brandLight: `#fff`,
    brandLighter: `#f5f3f7`,
    accentColor: `#ff8e00`, // "Material Orange"
    radius: 2,
    radiusLg: 4,
    gutters: {
      default: 0.5,
      HdR: 2.5,
      VHdR: 3,
      VVHdR: 4.5
    },
    shadowKeyUmbraOpacity: 0.1,
    shadowKeyPenumbraOpacity: 0.07,
    shadowAmbientShadowOpacity: 0.06,
    shadowColor: `157, 124, 191`,
    animation: {
      curveDefault: `cubic-bezier(0.4, 0, 0.2, 1)`,
      speedDefault: `250ms`,
      speedFast: `100ms`
    },
    logoOffset: 1.7,
    headerHeight: `3.5rem`
  };
  