import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const ButtonLinkVPN = React.forwardRef((props, ref) => <Link to="/Advanced_User/VPN_AVM_Fritzbox/"  role="button" {...props} ref={ref} />)
const ButtonLinkWebsite = React.forwardRef((props, ref) => <Link to="/Advanced_User/Website_Integration/"  role="button" {...props} ref={ref} />)
const ButtonLinkWebsiteHD = React.forwardRef((props, ref) => <Link to="/Advanced_User/Website_Integration/HD_Camera_Integration/"  role="button" {...props} ref={ref} />)
const ButtonLinkWebsiteCambozola = React.forwardRef((props, ref) => <Link to="/Advanced_User/Website_Integration/Cambozola/"  role="button" {...props} ref={ref} />)
const ButtonLinkWebsiteOIPCM = React.forwardRef((props, ref) => <Link to="/Advanced_User/Website_Integration/Open_IP_Camera_Manager/"  role="button" {...props} ref={ref} />)
const ButtonLinkYoutube = React.forwardRef((props, ref) => <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/"  role="button" {...props} ref={ref} />)
const ButtonLinkCGI = React.forwardRef((props, ref) => <Link to="/Advanced_User/CGI_Commands/"  role="button" {...props} ref={ref} />)
const ButtonLinkCGI1080 = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/"  role="button" {...props} ref={ref} />)
const ButtonLinkCGI720 = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/"  role="button" {...props} ref={ref} />)
const ButtonLinkCGIVGA = React.forwardRef((props, ref) => <Link to="/Advanced_User/CGI_Commands/VGA_Series_CGI_List/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTT = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTWindows = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTAndroid = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTHass = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTHomematic = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Homematic_CCU3_and_RedMatic/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTOpenHab = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTioBroker = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTNodeRED = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTHomey = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTHomee = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/homee/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTFhem = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/FHEM_House_Automation/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTHiveMQ = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/HiveMQ/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTMosquitto = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Mosquitto/"  role="button" {...props} ref={ref} />)
const ButtonLinkINSTARMQTTCloudMQTT = React.forwardRef((props, ref) => <Link to="/Advanced_User/INSTAR_MQTT_Broker/Cloud_MQTT/"  role="button" {...props} ref={ref} />)
const ButtonLinkHomebridge = React.forwardRef((props, ref) => <Link to="/Advanced_User/Homebridge_INSTAR_MQTT_to_Homekit/"  role="button" {...props} ref={ref} />)
const ButtonLinkFHEM = React.forwardRef((props, ref) => <Link to="/Advanced_User/FHEM_on_a_Raspberry_Pi/"  role="button" {...props} ref={ref} />)
const ButtonLinkMQTT = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_MQTT/"  role="button" {...props} ref={ref} />)
const ButtonLinkMQTTIntro = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_MQTT/Introduction/"  role="button" {...props} ref={ref} />)
const ButtonLinkMQTTInter = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_MQTT/Intermediate/"  role="button" {...props} ref={ref} />)
const ButtonLinkMQTTAdv = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_MQTT/Advanced/"  role="button" {...props} ref={ref} />)
const ButtonLinkMQTTPro = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_MQTT/Projects/"  role="button" {...props} ref={ref} />)
const ButtonLinkMQTTPhone = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_MQTT/Smartphone_Integration/"  role="button" {...props} ref={ref} />)
const ButtonLinkVideoDash = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_Dashboard_Live_Video/"  role="button" {...props} ref={ref} />)
const ButtonLinkASQueries = React.forwardRef((props, ref) => <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"  role="button" {...props} ref={ref} />)
const ButtonLinkSQL = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_SQL-Logging/"  role="button" {...props} ref={ref} />)
const ButtonLinkAndroid = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_on_Android/"  role="button" {...props} ref={ref} />)
const ButtonLinkHAB = React.forwardRef((props, ref) => <Link to="/Advanced_User/OpenHAB_Home_Automation/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABInstall = React.forwardRef((props, ref) => <Link to="/Advanced_User/OpenHAB_Home_Automation/OpenHAB_Installation_on_Windows/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABNodeRed = React.forwardRef((props, ref) => <Link to="/Advanced_User/OpenHAB_Home_Automation/Node-RED_Installation_on_Windows/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABMQTT = React.forwardRef((props, ref) => <Link to="/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABIPCam = React.forwardRef((props, ref) => <Link to="/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABian = React.forwardRef((props, ref) => <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABianHAB = React.forwardRef((props, ref) => <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/OpenHAB_Setup/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABianRED = React.forwardRef((props, ref) => <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABianMosquitto = React.forwardRef((props, ref) => <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/"  role="button" {...props} ref={ref} />)
const ButtonLinkHABianProjects = React.forwardRef((props, ref) => <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/Projects/"  role="button" {...props} ref={ref} />)
const ButtonLinkOpenHabIPCam = React.forwardRef((props, ref) => <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/"  role="button" {...props} ref={ref} />)
const ButtonLinkIOBroker = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/"  role="button" {...props} ref={ref} />)
const ButtonLinkIOBrokerWin = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Windows_10_Installation/"  role="button" {...props} ref={ref} />)
const ButtonLinkRaspian = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/Raspian_Preparation/"  role="button" {...props} ref={ref} />)
const ButtonLinkIOBrokerInstall = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Installation/"  role="button" {...props} ref={ref} />)
const ButtonLinkIOBrokerSetup = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/"  role="button" {...props} ref={ref} />)
const ButtonLinkVisualisation = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/Visualisation/"  role="button" {...props} ref={ref} />)
const ButtonLinkMotionEye = React.forwardRef((props, ref) => <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/"  role="button" {...props} ref={ref} />)
const ButtonLinkHomeMatic = React.forwardRef((props, ref) => <Link to="/Advanced_User/Homematic_CCU3_and_RedMatic/"  role="button" {...props} ref={ref} />)
const ButtonLinkAlexaNode = React.forwardRef((props, ref) => <Link to="/Advanced_User/Alexa_Voice_Control_without_Cloud/"  role="button" {...props} ref={ref} />)
const ButtonLinkIFTTT = React.forwardRef((props, ref) => <Link to="/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/"  role="button" {...props} ref={ref} />)
const ButtonLinkIFTTTAS = React.forwardRef((props, ref) => <Link to="/Advanced_User/IFTTT_as_Alarmserver/"  role="button" {...props} ref={ref} />)
const ButtonLinkIFTTTNode = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_and_IFTTT/"  role="button" {...props} ref={ref} />)
const ButtonLinkAlarmTimeline = React.forwardRef((props, ref) => <Link to="/Advanced_User/Node-RED_Alarm_Event_Timeline/"  role="button" {...props} ref={ref} />)
const ButtonLinkZigBee = React.forwardRef((props, ref) => <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/"  role="button" {...props} ref={ref} />)
const ButtonLinkZoneDock = React.forwardRef((props, ref) => <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/"  role="button" {...props} ref={ref} />)
const ButtonLinkTasker = React.forwardRef((props, ref) => <Link to="/Advanced_User/Tasker/"  role="button" {...props} ref={ref} />)
const ButtonLinkRestoreWeb = React.forwardRef((props, ref) => <Link to="/Advanced_User/Restore_WebUI/"  role="button" {...props} ref={ref} />)
const ButtonLinkRestoreFirm = React.forwardRef((props, ref) => <Link to="/Advanced_User/Restore_Firmware/"  role="button" {...props} ref={ref} />)
const ButtonLinkRestoreFirmVGA = React.forwardRef((props, ref) => <Link to="/Advanced_User/Restore_Firmware/VGA_Models/"  role="button" {...props} ref={ref} />)
const ButtonLinkRestoreCTTV = React.forwardRef((props, ref) => <Link to="/Advanced_User/CCTV_vs_IP/"  role="button" {...props} ref={ref} />)


function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Pour les développeurs
        </Typography>
      <ListItem button component={ButtonLinkVPN}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Tunnel VPN
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkWebsite}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Intégration du site
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkWebsiteHD}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Caméras HD (HTML5)
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkWebsiteCambozola}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Cambozola (Java)
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkWebsiteOIPCM}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ OIPCM (PHP)
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkCGI}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Commandes CGI
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkCGI1080}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Modèles 1080p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkCGI720}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Modèles 720p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkCGIVGA}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Modèles VGA
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMQTT}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Node-RED and MQTT
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMQTTIntro}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Introduction
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMQTTInter}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Intermediate
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMQTTAdv}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Advanced
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMQTTPro}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Projects
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMQTTPhone}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Phone Integration
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHAB}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          OpenHAB 2
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABInstall}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ OpenHAB 2 Installation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABNodeRed}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Node-RED Installation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABMQTT}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Mosquitto Installation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABIPCam}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IP Camera Control
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABian}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Raspberry Pi & openHABian
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABianHAB}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ OpenHAB Setup
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABianRED}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Node-RED Setup
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABianMosquitto}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Mosquitto Setup
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkHABianProjects}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Projects
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkIOBroker}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          ioBroker
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkIOBrokerWin}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Windows 10 (optional)
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkRaspian}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Raspian Preparation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkIOBrokerInstall}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ ioBroker Installation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkIOBrokerSetup}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ ioBroker Setup
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkVisualisation}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Visualisation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkMotionEye}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ motionEye
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkAlexaNode}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Node-RED & Alexa
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkIFTTTNode}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Node-RED & IFTTT
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkZigBee}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          ZigBee & XiaoMi Home
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkZoneDock}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          ZoneMinder & Docker
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkTasker}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Tasker Automatisation
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkRestoreWeb}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Restaurer WebUI
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkRestoreFirm}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Restaurer le micrologiciel
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkRestoreFirm}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Modèles 720p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkRestoreFirmVGA}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Modèles VGA
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLinkRestoreCTTV}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Analogue vs Numérique
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)








