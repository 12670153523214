import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Overview = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Overview/"  role="button" {...props} ref={ref} />)
const Web10800p = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/"  role="button" {...props} ref={ref} />)
const LiveVideo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Live_Video/"  role="button" {...props} ref={ref} />)
const Network = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/"  role="button" {...props} ref={ref} />)
const NetworkIPConf = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/IP_Configuration/"  role="button" {...props} ref={ref} />)
const NetworkCert = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/SSL_Certificate/"  role="button" {...props} ref={ref} />)
const NetworkWiFi = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/WiFi/"  role="button" {...props} ref={ref} />)
const NetworkDDNS = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/Remote_Access/"  role="button" {...props} ref={ref} />)
const NetworkMQTT = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/MQTT/"  role="button" {...props} ref={ref} />)
const NetworkUPnP = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/UPnP/"  role="button" {...props} ref={ref} />)
const NetworkONVIF = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Network/ONVIF/"  role="button" {...props} ref={ref} />)
const Multimedia = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Multimedia/"  role="button" {...props} ref={ref} />)
const MultimediaVideo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video/"  role="button" {...props} ref={ref} />)
const MultimediaImage = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/"  role="button" {...props} ref={ref} />)
const MultimediaPrivacy = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/"  role="button" {...props} ref={ref} />)
const MultimediaAudio = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/"  role="button" {...props} ref={ref} />)
const MultimediaOverlay = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video_Overlays/"  role="button" {...props} ref={ref} />)
const Feature = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/"  role="button" {...props} ref={ref} />)
const FeatureEmail = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/Email_Einstellungen/"  role="button" {...props} ref={ref} />)
const FeatureFTP = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/FTP/"  role="button" {...props} ref={ref} />)
const FeatureIR = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/IR_LEDs/"  role="button" {...props} ref={ref} />)
const FeaturePTZ = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/PTZ/"  role="button" {...props} ref={ref} />)
const FeaturePTZTour = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/PTZ_Tour/"  role="button" {...props} ref={ref} />)
const FeatureSD = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/SD_Card/"  role="button" {...props} ref={ref} />)
const FeatureStatus = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/Status_LED/"  role="button" {...props} ref={ref} />)
// const FeatureRecording = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Features/Manual_Recording/"  role="button" {...props} ref={ref} />)
const Alarm = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Alarm/"  role="button" {...props} ref={ref} />)
const AlarmActions = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Alarm/Actions/"  role="button" {...props} ref={ref} />)
const AlarmAreas = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Alarm/Areas/"  role="button" {...props} ref={ref} />)
const AlarmSchedule = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Alarm/Schedule/"  role="button" {...props} ref={ref} />)
const AlarmPush = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Alarm/Push_Service/"  role="button" {...props} ref={ref} />)
const AlarmAS = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"  role="button" {...props} ref={ref} />)
// const Recording = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Recording/"  role="button" {...props} ref={ref} />)
// const RecordingVideo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Recording/Video/"  role="button" {...props} ref={ref} />)
// const RecordingPhoto = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Recording/Photoseries/"  role="button" {...props} ref={ref} />)
const System = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/"  role="button" {...props} ref={ref} />)
const SystemInfo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Info/"  role="button" {...props} ref={ref} />)
const SystemUser = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/User/"  role="button" {...props} ref={ref} />)
const SystemWebUI = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/WebUI/"  role="button" {...props} ref={ref} />)
const SystemDate = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Date_Time/"  role="button" {...props} ref={ref} />)
const SystemLanguage = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Language/"  role="button" {...props} ref={ref} />)
const SystemLog = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Log/"  role="button" {...props} ref={ref} />)
const SystemUpdate = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Update/"  role="button" {...props} ref={ref} />)
const SystemReboot = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Reboot/"  role="button" {...props} ref={ref} />)
const SystemBackup = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Backup/"  role="button" {...props} ref={ref} />)
const SystemReset = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/System/Reset/"  role="button" {...props} ref={ref} />)
const Cloud = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Cloud/"  role="button" {...props} ref={ref} />)


function WebUIFHDNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
    <Typography variant="h5" className={classes.navtitle}>
    Interface utilisateur Web
      </Typography>
    <ListItem button component={Overview}>
      <Typography variant="subtitle1" className={classes.navsubtitle}>
      Aperçu
        </Typography>
    </ListItem>
    <ListItem button component={Web10800p}>
      <Typography variant="subtitle1" className={classes.navsubtitle}>
      WebUI 1080p
        </Typography>
    </ListItem>

    <ListItem button component={Network}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Réseau
        </Typography>
    </ListItem>
    <ListItem button component={NetworkIPConf}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Configuration IP
        </Typography>
    </ListItem>
    <ListItem button component={NetworkWiFi}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › WiFi
        </Typography>
    </ListItem>
    <ListItem button component={NetworkDDNS}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Accès à distance
        </Typography>
    </ListItem>
    <ListItem button component={NetworkUPnP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › UPnP Service
        </Typography>
    </ListItem>
    <ListItem button component={NetworkMQTT}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › MQTT
        </Typography>
    </ListItem>
    <ListItem button component={NetworkCert}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › SSL
        </Typography>
    </ListItem>
    <ListItem button component={NetworkONVIF}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Push Service
        </Typography>
    </ListItem>

    <ListItem button component={Multimedia}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Multimédia
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaVideo}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Vidéo
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaImage}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Image
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaPrivacy}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Masque de confidentialité
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaAudio}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › l&apos;audio
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaOverlay}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Superpositions vidéo
        </Typography>
    </ListItem>

    <ListItem button component={Feature}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Caractéristiques
        </Typography>
    </ListItem>
    <ListItem button component={FeatureEmail}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Notification par email
        </Typography>
    </ListItem>
    <ListItem button component={FeatureFTP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Téléchargement FTP
        </Typography>
    </ListItem>
    <ListItem button component={FeatureIR}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Vision nocturne IR
        </Typography>
    </ListItem>
    <ListItem button component={FeaturePTZ}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › PTZ
        </Typography>
    </ListItem>
    <ListItem button component={FeaturePTZTour}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › PTZ Tour
        </Typography>
    </ListItem>
    <ListItem button component={FeatureSD}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Carte SD
        </Typography>
    </ListItem>
    <ListItem button component={FeatureStatus}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › LED d&apos;état
        </Typography>
    </ListItem>
    {/* <ListItem button component={FeatureRecording}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Enregistrement manuel
        </Typography>
    </ListItem> */}

    <ListItem button component={Alarm}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Alarme
        </Typography>
    </ListItem>
    <ListItem button component={AlarmActions}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Actes
        </Typography>
    </ListItem>
    <ListItem button component={AlarmAreas}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Zones
        </Typography>
    </ListItem>
    <ListItem button component={AlarmSchedule}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Programme
        </Typography>
    </ListItem>
    <ListItem button component={AlarmPush}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Push Service
        </Typography>
    </ListItem>
    <ListItem button component={AlarmAS}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Serveur d&apos;alarme
        </Typography>
    </ListItem>

    {/* <ListItem button component={Recording}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Tâche d'enregistrement
        </Typography>
    </ListItem> */}
    {/* <ListItem button component={RecordingVideo}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Vidéo
        </Typography>
    </ListItem> */}
    {/* <ListItem button component={RecordingPhoto}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Série de photos
        </Typography>
    </ListItem> */}

    <ListItem button component={System}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Système
        </Typography>
    </ListItem>
    <ListItem button component={SystemInfo}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Aperçu
        </Typography>
    </ListItem>
    <ListItem button component={SystemUser}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Gestion des utilisateurs
        </Typography>
    </ListItem>
    <ListItem button component={SystemWebUI}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › WebUI
        </Typography>
    </ListItem>
    <ListItem button component={SystemDate}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Date et heure
        </Typography>
    </ListItem>
    <ListItem button component={SystemLanguage}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › La langue
        </Typography>
    </ListItem>
    <ListItem button component={SystemLog}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Journal du système
        </Typography>
    </ListItem>
    <ListItem button component={SystemUpdate}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Mettre à jour
        </Typography>
    </ListItem>
    <ListItem button component={SystemReboot}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Redémarrer
        </Typography>
    </ListItem>
    <ListItem button component={SystemBackup}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Backup
        </Typography>
    </ListItem>
    <ListItem button component={SystemReset}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Retour aux paramètres d&apos;usine
        </Typography>
    </ListItem>

    <ListItem button component={Cloud}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Cloud Menu
        </Typography>
    </ListItem>

    </List>
  );
}

WebUIFHDNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(WebUIFHDNav)