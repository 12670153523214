import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const ButtonLink01 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Setup/"  role="button" {...props} ref={ref} />)
const ButtonLink02 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Setup/720p_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink03 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Setup/VGA_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink04 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_Notification/"  role="button" {...props} ref={ref} />)
const ButtonLink05 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_Notification/720p_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink06 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_Notification/VGA_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink07 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_Notification/Troubleshooting/"  role="button" {...props} ref={ref} />)
// const ButtonLink08 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_Recording/"  role="button" {...props} ref={ref} />)
const ButtonLink09 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/"  role="button" {...props} ref={ref} />)
const ButtonLink10 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/Bitkinex/"  role="button" {...props} ref={ref} />)
const ButtonLink11 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/CuteFTP/"  role="button" {...props} ref={ref} />)
const ButtonLink12 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/DownThemAll/"  role="button" {...props} ref={ref} />)
const ButtonLink13 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/Interachy/"  role="button" {...props} ref={ref} />)
const ButtonLink14 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/DeepVacuum/"  role="button" {...props} ref={ref} />)
const ButtonLink15 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/SiteSucker/"  role="button" {...props} ref={ref} />)
const ButtonLink16 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/SD_Card_Access/SimpleWget/"  role="button" {...props} ref={ref} />)
const ButtonLink17 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_FTP_Upload/"  role="button" {...props} ref={ref} />)
const ButtonLink18 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_FTP_Upload/720p_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink19 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_FTP_Upload/VGA_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink20 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Router_as_a_FTP_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink21 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink22 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Router_as_a_FTP_Server/Telekom_Speedport_as_FTP_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink23 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Router_as_a_FTP_Server/QNAP_as_FTP_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink24 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud_as_FTP_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink25 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Router_as_a_FTP_Server/Synology_as_FTP_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink26 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/FTP_Server_Setup/"  role="button" {...props} ref={ref} />)
const ButtonLink27 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/FTP_Server_Setup/Install_FileZilla_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink28 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/Alarm_Server/"  role="button" {...props} ref={ref} />)
const ButtonLink29 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/"  role="button" {...props} ref={ref} />)
const ButtonLink30 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/Create_User_Account/"  role="button" {...props} ref={ref} />)
const ButtonLink31 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/Storage/"  role="button" {...props} ref={ref} />)
const ButtonLink32 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/User/"  role="button" {...props} ref={ref} />)
const ButtonLink33 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/Administration/"  role="button" {...props} ref={ref} />)
const ButtonLink34 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/Alarm_Recordings/"  role="button" {...props} ref={ref} />)
const ButtonLink35 = React.forwardRef((props, ref) => <Link to="/Motion_Detection/INSTAR_Cloud/Camera_Sets/"  role="button" {...props} ref={ref} />)

function MotionNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Détection de mouvement
        </Typography>
      <ListItem button component={ButtonLink01}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Installer
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink01}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 1080p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink02}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 720p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink03}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série VGA
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink04}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Notification d&apos;alarme
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink04}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 1080p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink05}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 720p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink06}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série VGA
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink07}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Dépannage
          </Typography>
      </ListItem>
      {/* <ListItem button component={ButtonLink08}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Enregistrement video
          </Typography>
      </ListItem> */}
      <ListItem button component={ButtonLink09}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Accès à la carte SD
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink10}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Bitkinex
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink11}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ CuteFTP
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink12}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ DownThemAll
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink13}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Interachy
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink14}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ DeepVacuum
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink15}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ SiteSucker
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink16}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ SimpleWget
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink17}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Alarme FTP Télécharger
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink17}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 1080p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink18}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 720p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink19}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série VGA
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink20}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Routeur en tant que serveur FTP
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink21}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ AVM Fritzbox
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink22}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Telekom Speedport
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink23}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ QNAP
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink24}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ WD MyCloud
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink25}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Synology
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink26}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Configuration du serveur FTP
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink27}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Installation du serveur FTP
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink28}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Serveur d&apos;alarme
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink29}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          INSTAR Cloud
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink30}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Créer un compte d&apos;utilisateur
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink31}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Espace de rangement
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink32}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Utilisateur
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink33}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Administration
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink34}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Enregistrements d&apos;alarme
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink35}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Ensembles de caméra
          </Typography>
      </ListItem>
    </List>
  );
}

MotionNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

// export default withRoot(withStyles(styles)(MotionNav))

export default withStyles(styles, { withTheme: true })(MotionNav)