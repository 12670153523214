import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareOther = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/"  role="button" {...props} ref={ref} />)
const SoftwareOtherInstarVision = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/InstarVision_Blackberry/"  role="button" {...props} ref={ref} />)
const SoftwareOtherSynology = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Synology/"  role="button" {...props} ref={ref} />)
const SoftwareOtherHomee = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Homee/"  role="button" {...props} ref={ref} />)
const SoftwareOtherHomeeRed = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Homee_and_Node-RED/"  role="button" {...props} ref={ref} />)
const SoftwareOtherFritzphone = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/AVM_Fritzphone/"  role="button" {...props} ref={ref} />)
const SoftwareOtherHomematic = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Homematic_IP/"  role="button" {...props} ref={ref} />)
const SoftwareOtherLoxone = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Loxone_Miniserver/"  role="button" {...props} ref={ref} />)
const SoftwareOtherQNAP = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/QNAP/"  role="button" {...props} ref={ref} />)
const SoftwareOtherDomovea = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Domovea/"  role="button" {...props} ref={ref} />)
const SoftwareOtherDomoveav2 = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Hager_Domovea/"  role="button" {...props} ref={ref} />)
const SoftwareOtherASUSTOR = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/ASUSTOR_Surveillance_Center/"  role="button" {...props} ref={ref} />)
const SoftwareHomeMonocle = React.forwardRef((props, ref) => <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/"  role="button" {...props} ref={ref} />)
const SoftwareOtherIPSymcon = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/IP_Symcon/"  role="button" {...props} ref={ref} />)
const SoftwareOtherHomey = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/Athom_Homey/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHarmony = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Logitech_Harmony/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Thirdparty Platforms
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>


      <ListItem button component={SoftwareOther}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Other Platforms
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherInstarVision}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision for Blackberry
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherHomee}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › homee
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherHomeeRed}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › homee and Node-RED
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherSynology}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Synology
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherHomey}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Athom Homey
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherHomematic}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Homematic IP
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherLoxone}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Loxone Miniserver
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHarmony}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Logitech Harmony
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeMonocle}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Alexa & Monocle Gateway
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherFritzphone}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › AVM Fritzphone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherIPSymcon}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IP Symcon Smarthome
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherQNAP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › QNAP
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherDomovea}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Domovea
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherDomoveav2}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Domovea v2
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareOtherASUSTOR}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › ASUSTOR Surveillance Center
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)