import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const OutdoorCams = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/"  role="button" {...props} ref={ref} />)
const In5907 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/"  role="button" {...props} ref={ref} />)
const In5907Manual = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Usermanual/"  role="button" {...props} ref={ref} />)
const In5907QS = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Quick_Installation/"  role="button" {...props} ref={ref} />)
const In5907Warning = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Safety_Warnings/"  role="button" {...props} ref={ref} />)
const In5907Features = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Product_Features/"  role="button" {...props} ref={ref} />)
const In5907Specs = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Technical_Specifications/"  role="button" {...props} ref={ref} />)
const In5907Reset = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Camera_Reset/"  role="button" {...props} ref={ref} />)
const In5907Lense = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/"  role="button" {...props} ref={ref} />)
const In5907Warranty = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Warranty/"  role="button" {...props} ref={ref} />)
const In5907Streaming = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/Video_Streaming/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>

      <ListItem button component={OutdoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Outdoor Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In5907}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-5907 HD
          </Typography>
      </ListItem>
      <ListItem button component={In5907Manual}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Usermanual
          </Typography>
      </ListItem>
      <ListItem button component={In5907QS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Installation
          </Typography>
      </ListItem>
      <ListItem button component={In5907Warning}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Safety Warnings
          </Typography>
      </ListItem>
      <ListItem button component={In5907Features}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Product Features
          </Typography>
      </ListItem>
      <ListItem button component={In5907Specs}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Technical Specifications
          </Typography>
      </ListItem>
      <ListItem button component={In5907Reset}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Reset
          </Typography>
      </ListItem>
      <ListItem button component={In5907Lense}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Lense Adjustment
          </Typography>
      </ListItem>
      <ListItem button component={In5907Streaming}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video Streaming
          </Typography>
      </ListItem>
      <ListItem button component={In5907Warranty}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Warranty & Disposal
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)