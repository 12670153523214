import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Overview = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Overview/"  role="button" {...props} ref={ref} />)
const Web720p = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/"  role="button" {...props} ref={ref} />)
const Software = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Software/"  role="button" {...props} ref={ref} />)
const SoftwareLang = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Software/Language/"  role="button" {...props} ref={ref} />)
const SoftwareReboot = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Software/Reboot_Reset/"  role="button" {...props} ref={ref} />)
const SoftwareBackup = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Software/Backup/"  role="button" {...props} ref={ref} />)
const SoftwareUpdate = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Software/Update/"  role="button" {...props} ref={ref} />)
const Network = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/"  role="button" {...props} ref={ref} />)
const NetworkIPConf = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/IP_Configuration/"  role="button" {...props} ref={ref} />)
const NetworkWiFi = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/WiFi/"  role="button" {...props} ref={ref} />)
const NetworkDDNS = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/DDNS/"  role="button" {...props} ref={ref} />)
const NetworkP2P = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/P2P/"  role="button" {...props} ref={ref} />)
const NetworkUPnP = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/UPnP/"  role="button" {...props} ref={ref} />)
const NetworkPush = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Network/Push_Service/"  role="button" {...props} ref={ref} />)
const System = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/"  role="button" {...props} ref={ref} />)
const SystemInfo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/Info/"  role="button" {...props} ref={ref} />)
const SystemDate = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/Date_Time/"  role="button" {...props} ref={ref} />)
const SystemUser = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/User/"  role="button" {...props} ref={ref} />)
const SystemStatus = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/Status_LED/"  role="button" {...props} ref={ref} />)
const SystemIR = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/IR_LEDs/"  role="button" {...props} ref={ref} />)
const SystemONVIF = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/ONVIF/"  role="button" {...props} ref={ref} />)
const SystemPTZ = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/PTZ/"  role="button" {...props} ref={ref} />)
const SystemPTZTour = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/PTZ_Tour/"  role="button" {...props} ref={ref} />)
const SystemLog = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/System/System_Log/"  role="button" {...props} ref={ref} />)
const Multimedia = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Multimedia/"  role="button" {...props} ref={ref} />)
const MultimediaVideo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Multimedia/Video/"  role="button" {...props} ref={ref} />)
const MultimediaImage = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Multimedia/Image/"  role="button" {...props} ref={ref} />)
const MultimediaPrivacy = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Multimedia/Privacy_Mask/"  role="button" {...props} ref={ref} />)
const MultimediaAudio = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Multimedia/Audio/"  role="button" {...props} ref={ref} />)
const Alarm = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/"  role="button" {...props} ref={ref} />)
const AlarmAreas = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/Areas/"  role="button" {...props} ref={ref} />)
const AlarmActions = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/Actions/"  role="button" {...props} ref={ref} />)
const AlarmSchedule = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/Schedule/"  role="button" {...props} ref={ref} />)
const AlarmSMTP = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/SMTP_Server/"  role="button" {...props} ref={ref} />)
const AlarmEmail = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/Email/"  role="button" {...props} ref={ref} />)
const AlarmFTP = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/FTP/"  role="button" {...props} ref={ref} />)
const AlarmAS = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/Alarmserver/"  role="button" {...props} ref={ref} />)
const AlarmAudio = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/Audio_Alarm/"  role="button" {...props} ref={ref} />)
const AlarmSD = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Alarm/SD_Card/"  role="button" {...props} ref={ref} />)
// const Recording = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Recording/"  role="button" {...props} ref={ref} />)
// const RecordingSchedule = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Recording/Schedule/"  role="button" {...props} ref={ref} />)
// const RecordingVideo = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Recording/Video/"  role="button" {...props} ref={ref} />)
// const RecordingPhoto = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Recording/Photoseries/"  role="button" {...props} ref={ref} />)
const Cloud = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Cloud/"  role="button" {...props} ref={ref} />)
const Menu = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Menu_Bar/"  role="button" {...props} ref={ref} />)
const VideoMenu = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/Video_Menu_Bar/"  role="button" {...props} ref={ref} />)
const PTZControl = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/PTZ_Control/"  role="button" {...props} ref={ref} />)


function WebUIHDNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
    <Typography variant="h5" className={classes.navtitle}>
    Interface utilisateur Web
      </Typography>
    <ListItem button component={Overview}>
      <Typography variant="subtitle1" className={classes.navsubtitle}>
      Aperçu
        </Typography>
    </ListItem>
    <ListItem button component={Web720p}>
      <Typography variant="subtitle1" className={classes.navsubtitle}>
      Interface Web 720p
        </Typography>
    </ListItem>

    <ListItem button component={Software}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Logiciel
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareLang}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › La langue
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareReboot}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Redémarrer et réinitialiser
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareBackup}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Sauvegarde
        </Typography>
    </ListItem>
    <ListItem button component={SoftwareUpdate}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Mise à jour logicielle
        </Typography>
    </ListItem>

    <ListItem button component={Network}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Réseau
        </Typography>
    </ListItem>
    <ListItem button component={NetworkIPConf}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Configuration IP
        </Typography>
    </ListItem>
    <ListItem button component={NetworkWiFi}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › WiFi
        </Typography>
    </ListItem>
    <ListItem button component={NetworkDDNS}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › DDNS Service
        </Typography>
    </ListItem>
    <ListItem button component={NetworkP2P}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › P2P Service
        </Typography>
    </ListItem>
    <ListItem button component={NetworkUPnP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › UPnP Service
        </Typography>
    </ListItem>
    <ListItem button component={NetworkPush}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Push Service
        </Typography>
    </ListItem>
    <ListItem button component={NetworkPush}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Push Service
        </Typography>
    </ListItem>

    <ListItem button component={System}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Système
        </Typography>
    </ListItem>
    <ListItem button component={SystemInfo}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Info
        </Typography>
    </ListItem>
    <ListItem button component={SystemDate}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Date et heure
        </Typography>
    </ListItem>
    <ListItem button component={SystemUser}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Gestion des utilisateurs
        </Typography>
    </ListItem>
    <ListItem button component={SystemStatus}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › LED d&apos;état
        </Typography>
    </ListItem>
    <ListItem button component={SystemIR}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › IR LEDs
        </Typography>
    </ListItem>
    <ListItem button component={SystemONVIF}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › ONVIF
        </Typography>
    </ListItem>
    <ListItem button component={SystemPTZ}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Pan et inclinaison
        </Typography>
    </ListItem>
    <ListItem button component={SystemPTZTour}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › PTZ Tour
        </Typography>
    </ListItem>
    <ListItem button component={SystemLog}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Journal du système
        </Typography>
    </ListItem>

    <ListItem button component={Multimedia}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Multimédia
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaVideo}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Vidéo
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaImage}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Image
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaPrivacy}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Masque de confidentialité
        </Typography>
    </ListItem>
    <ListItem button component={MultimediaAudio}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › l&apos;audio
        </Typography>
    </ListItem>

    <ListItem button component={Alarm}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Alarme
        </Typography>
    </ListItem>
    <ListItem button component={AlarmAreas}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Zones
        </Typography>
    </ListItem>
    <ListItem button component={AlarmActions}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Actes
        </Typography>
    </ListItem>
    <ListItem button component={AlarmSchedule}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Programme
        </Typography>
    </ListItem>
    <ListItem button component={AlarmSMTP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Serveur SMTP
        </Typography>
    </ListItem>
    <ListItem button component={AlarmEmail}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Alarme Email
        </Typography>
    </ListItem>
    <ListItem button component={AlarmFTP}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Alarme FTP
        </Typography>
    </ListItem>
    <ListItem button component={AlarmAS}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Serveur d&apos;alarme
        </Typography>
    </ListItem>
    <ListItem button component={AlarmAudio}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Alarme audio
        </Typography>
    </ListItem>
    <ListItem button component={AlarmSD}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Carte SD
        </Typography>
    </ListItem>

    {/* <ListItem button component={Recording}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Tâche d'enregistrement
        </Typography>
    </ListItem> */}
    {/* <ListItem button component={RecordingSchedule}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Programme
        </Typography>
    </ListItem>
    <ListItem button component={RecordingVideo}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Vidéo
        </Typography>
    </ListItem>
    <ListItem button component={RecordingPhoto}>
      <Typography variant="caption" className={classes.navsubtitlesecindent}>
        › Série de photos
        </Typography>
    </ListItem> */}

    <ListItem button component={Cloud}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Cloud Menu
        </Typography>
    </ListItem>

    <ListItem button component={Menu}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Barre de menu
        </Typography>
    </ListItem>

    <ListItem button component={VideoMenu}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Barre de menu vidéo
        </Typography>
    </ListItem>

    <ListItem button component={PTZControl}>
      <Typography variant="caption" className={classes.navsubtitleindent}>
        ▪ Contrôle PTZ
        </Typography>
    </ListItem>

    </List>
  );
}

WebUIHDNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(WebUIHDNav)