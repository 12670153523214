import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const IndoorCams = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/"  role="button" {...props} ref={ref} />)
const In8001 = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/"  role="button" {...props} ref={ref} />)
const In8001Manual = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Usermanual/"  role="button" {...props} ref={ref} />)
const In8001QS = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Quick_Installation/"  role="button" {...props} ref={ref} />)
const In8001Warning = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Safety_Warnings/"  role="button" {...props} ref={ref} />)
const In8001Features = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Product_Features/"  role="button" {...props} ref={ref} />)
const In8001Specs = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Technical_Specifications/"  role="button" {...props} ref={ref} />)
const In8001Reset = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Camera_Reset/"  role="button" {...props} ref={ref} />)
const In8001Lense = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Lense_Adjustment/"  role="button" {...props} ref={ref} />)
const In8001Warranty = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Warranty/"  role="button" {...props} ref={ref} />)
const In8001Streaming = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/IN-8001_HD/Video_Streaming/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>
      <ListItem button component={IndoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Indoor Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In8001}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-8001 Full HD
          </Typography>
      </ListItem>
      <ListItem button component={In8001Manual}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Usermanual
          </Typography>
      </ListItem>
      <ListItem button component={In8001QS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Installation
          </Typography>
      </ListItem>
      <ListItem button component={In8001Warning}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Safety Warnings
          </Typography>
      </ListItem>
      <ListItem button component={In8001Features}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Product Features
          </Typography>
      </ListItem>
      <ListItem button component={In8001Specs}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Technical Specifications
          </Typography>
      </ListItem>
      <ListItem button component={In8001Reset}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Camera Reset
          </Typography>
      </ListItem>
      <ListItem button component={In8001Lense}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Lense Adjustment
          </Typography>
      </ListItem>
      <ListItem button component={In8001Streaming}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video Streaming
          </Typography>
      </ListItem>
      <ListItem button component={In8001Warranty}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Warranty & Disposal
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles, { withTheme: true })(ProductNav)