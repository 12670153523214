import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})


const CGICommands = React.forwardRef((props, ref) => <Link to="/Advanced_User/CGI_Commands/"  role="button" {...props} ref={ref} />)
const CGICommands720p = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarm = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmActions = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Actions/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmAS = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Alarm_Server/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmAreas = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Areas/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmAudio = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Audio_Alarm/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmEmail = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Email/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmFTP = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/FTP/"  role="button" {...props} ref={ref} />)
const CGICommands720pPrivacy = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Privacy_Mask/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmSchedule = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/Schedule/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmSD = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/SD_Card/"  role="button" {...props} ref={ref} />)
const CGICommands720pAlarmSMTP = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Alarm_Menu/SMTP_Server/"  role="button" {...props} ref={ref} />)
const CGICommands720pMulti = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Multimedia_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands720pMultiAudio = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Multimedia_Menu/Audio/"  role="button" {...props} ref={ref} />)
const CGICommands720pMultiImage = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Multimedia_Menu/Image/"  role="button" {...props} ref={ref} />)
const CGICommands720pMultiVideo = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Multimedia_Menu/Video/"  role="button" {...props} ref={ref} />)
const CGICommands720pNet = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands720pNetDDNS = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/DDNS/"  role="button" {...props} ref={ref} />)
const CGICommands720pNetConfig = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/IP_Config/"  role="button" {...props} ref={ref} />)
const CGICommands720pNetP2P = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/P2P/"  role="button" {...props} ref={ref} />)
const CGICommands720pNetPush = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/Push_Service/"  role="button" {...props} ref={ref} />)
const CGICommands720pNetUPnP = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/UPnP/"  role="button" {...props} ref={ref} />)
const CGICommands720pNetWiFi = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Network_Menu/WiFi/"  role="button" {...props} ref={ref} />)
// const CGICommands720pRec = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Recording_Menu/"  role="button" {...props} ref={ref} />)
// const CGICommands720pRecPhotoseries = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Recording_Menu/Photoseries/"  role="button" {...props} ref={ref} />)
// const CGICommands720pRecSchedule = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Recording_Menu/Schedule/"  role="button" {...props} ref={ref} />)
// const CGICommands720pRecVideo = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Recording_Menu/Video/"  role="button" {...props} ref={ref} />)
const CGICommands720pSoftware = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Software_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands720pSoftwareBack = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Software_Menu/Backup-Restore/"  role="button" {...props} ref={ref} />)
const CGICommands720pSoftwareLang = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Software_Menu/Language/"  role="button" {...props} ref={ref} />)
const CGICommands720pSoftwareReboot = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Software_Menu/Reboot-Reset/"  role="button" {...props} ref={ref} />)
const CGICommands720pSoftwareUpdate = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/Software_Menu/Update/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystem = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemDate = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/Date-Time/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemInfo = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/Info/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemIR = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/IR_LEDs/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemLog = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/Log/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemONVIF = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/ONVIF/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemPTZ = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/PTZ/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemPTZTour = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/PTZ_Tour/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemStatus = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/Status_LED/"  role="button" {...props} ref={ref} />)
const CGICommands720pSystemUser = React.forwardRef((props, ref) => <Link to="/720p_Series_CGI_List/System_Menu/User/"  role="button" {...props} ref={ref} />)


function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Pour les développeurs
        </Typography>
      <ListItem button component={CGICommands}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Commandes CGI
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720p}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          720p Commandes CGI
          </Typography>
      </ListItem>

      <ListItem button component={CGICommands720pAlarm}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Alarm Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmActions}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Actions
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmAS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Alarm Server
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmAreas}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Alarm Areas
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmAudio}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Audio Alarm
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmEmail}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Email
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmFTP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › FTP
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pPrivacy}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Privacy Mask
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmSchedule}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Schedule
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmSD}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › SD Card
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pAlarmSMTP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › SMTP
          </Typography>
      </ListItem>


      <ListItem button component={CGICommands720pMulti}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Multimedia Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pMultiAudio}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Audio
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pMultiImage}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Image
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pMultiVideo}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video
          </Typography>
      </ListItem>


      <ListItem button component={CGICommands720pNet}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Network Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pNetDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › DDNS
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pNetConfig}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IP Configuration
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pNetP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › P2P
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pNetPush}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Push Service
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pNetUPnP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › UPnP
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pNetWiFi}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › WiFi
          </Typography>
      </ListItem>


      {/* <ListItem button component={CGICommands720pRec}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pRecPhotoseries}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Photoseries
          </Typography>
      </ListItem> */}
      {/* <ListItem button component={CGICommands720pRecSchedule}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Schedule
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pRecVideo}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video
          </Typography>
      </ListItem> */}


      <ListItem button component={CGICommands720pSoftware}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Software Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSoftwareBack}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Backup & Restore
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSoftwareLang}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Language
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSoftwareReboot}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Reboot & Reset
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSoftwareUpdate}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Update
          </Typography>
      </ListItem>



      <ListItem button component={CGICommands720pSystem}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ System Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemDate}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Date & Time
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemInfo}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Info
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemIR}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IR LEDs
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemLog}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › System Log
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemONVIF}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › ONVIF
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemPTZ}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › PTZ
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemPTZTour}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › PTZ Tour
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemStatus}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Status LEDs
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands720pSystemUser}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › User Management
          </Typography>
      </ListItem>


    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)