import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})


const CompareCams = React.forwardRef((props, ref) => <Link to="/Products/Compare/"  role="button" {...props} ref={ref} />)
const IndoorCams = React.forwardRef((props, ref) => <Link to="/Indoor_Cameras/"  role="button" {...props} ref={ref} />)
const OutdoorCams = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/"  role="button" {...props} ref={ref} />)
const CamLenses = React.forwardRef((props, ref) => <Link to="/Products/Lenses/"  role="button" {...props} ref={ref} />)
const Lenses8015 = React.forwardRef((props, ref) => <Link to="/Products/Lenses/IN-8015/"  role="button" {...props} ref={ref} />)
const Lenses9008 = React.forwardRef((props, ref) => <Link to="/Products/Lenses/IN-9008/"  role="button" {...props} ref={ref} />)
const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareWin = React.forwardRef((props, ref) => <Link to="/Software/Windows/"  role="button" {...props} ref={ref} />)
const SoftwareWinIV2 = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVWP = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Windows_Phone/"  role="button" {...props} ref={ref} />)
const SoftwareWinIVMetro = React.forwardRef((props, ref) => <Link to="/Software/Windows/InstarVision/Metro/"  role="button" {...props} ref={ref} />)
const SoftwareMacOS = React.forwardRef((props, ref) => <Link to="/Software/macOS/"  role="button" {...props} ref={ref} />)
const SoftwareLinux = React.forwardRef((props, ref) => <Link to="/Software/Linux/"  role="button" {...props} ref={ref} />)
const SoftwareAnd = React.forwardRef((props, ref) => <Link to="/Software/Android/"  role="button" {...props} ref={ref} />)
const SoftwareAndIV = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareIOs = React.forwardRef((props, ref) => <Link to="/Software/iOS/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIV = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareHome = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/"  role="button" {...props} ref={ref} />)
const SoftwareOther = React.forwardRef((props, ref) => <Link to="/Software/Other_Platforms/"  role="button" {...props} ref={ref} />)
const ButtonLink11 = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Overview/"  role="button" {...props} ref={ref} />)
const ButtonLink12 = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink13 = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/720p_Series/"  role="button" {...props} ref={ref} />)
const ButtonLink14 = React.forwardRef((props, ref) => <Link to="/Web_User_Interface/1080p_Series/Overview/"  role="button" {...props} ref={ref} />)
const ButtonWebcam = React.forwardRef((props, ref) => <Link to="/Products/USB-Webcams/IN-W1/"  role="button" {...props} ref={ref} />)
const ButtonLink15 = React.forwardRef((props, ref) => <Link to="/Products/IN-LAN/"  role="button" {...props} ref={ref} />)
const ButtonLink16 = React.forwardRef((props, ref) => <Link to="/Products/Power_over_Ethernet/"  role="button" {...props} ref={ref} />)
const ButtonLink17 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/"  role="button" {...props} ref={ref} />)
const ButtonLink18 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/First_Steps/"  role="button" {...props} ref={ref} />)
const ButtonLink19 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/Overview/"  role="button" {...props} ref={ref} />)
const ButtonLink20 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/Network/"  role="button" {...props} ref={ref} />)
const ButtonLink21 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/Firewall/"  role="button" {...props} ref={ref} />)
const ButtonLink22 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/System/"  role="button" {...props} ref={ref} />)
const ButtonLink23 = React.forwardRef((props, ref) => <Link to="/Products/IN-Route/Help/"  role="button" {...props} ref={ref} />)
const ButtonLink24 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/"  role="button" {...props} ref={ref} />)
const ButtonLink25 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/300/"  role="button" {...props} ref={ref} />)
const ButtonLink26 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/"  role="button" {...props} ref={ref} />)
const ButtonLink27 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/Dual_Sensor/"  role="button" {...props} ref={ref} />)
const ButtonLink28 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/Setup/"  role="button" {...props} ref={ref} />)
const ButtonLink29 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/Wiring/"  role="button" {...props} ref={ref} />)
const ButtonLink30 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/User_Interface/"  role="button" {...props} ref={ref} />)
const ButtonLink31 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/Considerations/"  role="button" {...props} ref={ref} />)
const ButtonLink32 = React.forwardRef((props, ref) => <Link to="/Products/IN-Motion/500/Recorder/"  role="button" {...props} ref={ref} />)
const ButtonLink33 = React.forwardRef((props, ref) => <Link to="/Products/IN-Mikro/"  role="button" {...props} ref={ref} />)
const ButtonLink34 = React.forwardRef((props, ref) => <Link to="/Products/IR_Lighting/"  role="button" {...props} ref={ref} />)
const ButtonLink35 = React.forwardRef((props, ref) => <Link to="/Downloads/"  role="button" {...props} ref={ref} />)
const ButtonLink36 = React.forwardRef((props, ref) => <Link to="/Products/Usermanuals/"  role="button" {...props} ref={ref} />)


function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Des produits
        </Typography>
      <ListItem button component={IndoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Caméras d&apos;intérieur
          </Typography>
      </ListItem>

      <ListItem button component={OutdoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Caméras d&apos;extérieur
          </Typography>
      </ListItem>

      <ListItem button component={CamLenses}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Lentilles
          </Typography>
      </ListItem>
      <ListItem button component={Lenses9008}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-8015 Full HD
          </Typography>
      </ListItem>
      <ListItem button component={Lenses8015}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-9008 Full HD
          </Typography>
      </ListItem>

      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Logiciel
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareWin}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Windows
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIV2}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision v2
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVWP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision for Windows Phone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareWinIVMetro}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision for Windows Metro
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareMacOS}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ macOS
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareLinux}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Linux
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareAnd}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Android
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIV}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision for Android
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareIOs}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ iOS
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIV}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › InstarVision for iPhone & iPad
          </Typography>
      </ListItem>

      <ListItem button component={SoftwareOther}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Autres plates-formes
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink11}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Interface utilisateur Web
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink12}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 1080p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink13}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série 720p
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink14}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Série VGA
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink15}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          IN-LAN
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink16}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          IN-PoE
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink17}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          IN-Route
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink18}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Premiers pas
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink19}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Aperçu
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink20}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Réseau
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink21}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Firewall
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink22}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Système
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink23}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Aidez-moi
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink24}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          IN-Motion
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink25}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-Motion 300
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink26}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-Motion 500
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink27}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Double capteur
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink28}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Installer
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink29}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Câblage
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink30}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Interface utilisateur
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink31}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Considérations
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink32}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Enregistreur
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink33}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          IN-Mikro
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink34}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Projecteurs infrarouges
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink35}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Téléchargements
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink36}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Manuels d&apos;utilisation
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles, { withTheme: true })(ProductNav)