import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareAnd = React.forwardRef((props, ref) => <Link to="/Software/Android/"  role="button" {...props} ref={ref} />)
const SoftwareAndIV = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareAndIVDDNS = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/DDNS/"  role="button" {...props} ref={ref} />)
const SoftwareAndIVP2P = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/P2P/"  role="button" {...props} ref={ref} />)
const SoftwareAndIVMulti = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/Multiview/"  role="button" {...props} ref={ref} />)
const SoftwareAndIVRec = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/Recording/"  role="button" {...props} ref={ref} />)
const SoftwareAndIVAdvanced = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/Advanced/"  role="button" {...props} ref={ref} />)
const SoftwareAndIVSettings = React.forwardRef((props, ref) => <Link to="/Software/Android/InstarVision/Settings/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        InstarVision Android
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAnd}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          ▪ Android Software
          </Typography>
      </ListItem>


      <ListItem button component={SoftwareAndIV}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for Android
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIVDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - DDNS Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIVP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - P2P Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIVMulti}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Multiview Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIVAdvanced}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Advanced Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIVRec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareAndIVSettings}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Settings Menu
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)