import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareHome = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHomee = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Homee/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHomey = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Athom_Homey/"  role="button" {...props} ref={ref} />)
const SoftwareHomeIPSymcon = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/IP_Symcon/"  role="button" {...props} ref={ref} />)
const SoftwareHomeLox = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Loxone/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHomematic = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Homematic/"  role="button" {...props} ref={ref} />)
const SoftwareHomeIFTTT = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/IFTTT/"  role="button" {...props} ref={ref} />)
const SoftwareHomeFHEM = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/FHEM/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHass = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Home_Assistant/"  role="button" {...props} ref={ref} />)
const SoftwareHomeIO = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/IOBroker/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHab = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/OpenHAB2/"  role="button" {...props} ref={ref} />)
const SoftwareHomeRed = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Node-RED/"  role="button" {...props} ref={ref} />)
const SoftwareHomeMonocle = React.forwardRef((props, ref) => <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/"  role="button" {...props} ref={ref} />)
const SoftwareHomeHarmony = React.forwardRef((props, ref) => <Link to="/Software/Home_Automation/Logitech_Harmony/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Smarthome
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>


      <ListItem button component={SoftwareHome}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Smarthome
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeLox}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Loxone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHomee}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › homee
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHomematic}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Homematic
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHomey}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Athom Homey
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeIFTTT}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IFTTT
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeIPSymcon}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IP Symcon
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeFHEM}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › FHEM
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHass}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Home Assistant
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeIO}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IO Broker
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHab}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › OpenHAB
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeRed}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Node-RED
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeHarmony}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Logitech Harmony
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareHomeMonocle}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Alexa & Monocle Gateway
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)