import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const Software = React.forwardRef((props, ref) => <Link to="/Software/"  role="button" {...props} ref={ref} />)
const SoftwareIOs = React.forwardRef((props, ref) => <Link to="/Software/iOS/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIV = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPhone = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPhone/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPhoneDDNS = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPhone/DDNS/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPhoneP2P = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPhone/P2P/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPhoneMulti = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPhone/Multiview/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPhoneRec = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPhone/Recording/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPhoneSettings = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPhone/Settings/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPad = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPad/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPadDDNS = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPad/DDNS/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPadP2P = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPad/P2P/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPadMulti = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPad/Multiview/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPadRec = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPad/Recording/"  role="button" {...props} ref={ref} />)
const SoftwareIOsIVIPadSettings = React.forwardRef((props, ref) => <Link to="/Software/iOS/InstarVision/iPad/Settings/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        InstarVision iOS
        </Typography>
      <ListItem button component={Software}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Software
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOs}>
        <Typography variant="caption" className={classes.navsubtitle}>
          ▪ iOS Software
          </Typography>
      </ListItem>

      
      <ListItem button component={SoftwareIOsIV}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for iPhone & iPad
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPhone}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for iPhone
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPhoneDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - DDNS Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPhoneP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - P2P Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPhoneMulti}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Multiview Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPhoneRec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPhoneSettings}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Settings Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPad}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          › InstarVision for iPad
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPadDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - DDNS Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPadP2P}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - P2P Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPadMulti}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Multiview Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPadRec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={SoftwareIOsIVIPadSettings}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          - Settings Menu
          </Typography>
      </ListItem>

    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)