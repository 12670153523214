import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const OutdoorCams = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/"  role="button" {...props} ref={ref} />)

const CompareOutdoorCams = React.forwardRef((props, ref) => <Link to="/Products/Compare/Outdoor_Cameras/"  role="button" {...props} ref={ref} />)

const In9020 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9020_HD/"  role="button" {...props} ref={ref} />)

const In9010 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9010_HD/"  role="button" {...props} ref={ref} />)

const In9008 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-9008_HD/"  role="button" {...props} ref={ref} />)

const In7011 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-7011_HD/"  role="button" {...props} ref={ref} />)

const In5907 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5907_HD/"  role="button" {...props} ref={ref} />)

const In5905 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-5905_HD/"  role="button" {...props} ref={ref} />)

const In2905 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-2905_V2/"  role="button" {...props} ref={ref} />)

const In2908 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-2908/"  role="button" {...props} ref={ref} />)

const In4010 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-4010_V2/"  role="button" {...props} ref={ref} />)

const In4011 = React.forwardRef((props, ref) => <Link to="/Outdoor_Cameras/IN-4011/"  role="button" {...props} ref={ref} />)

function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
        Products
        </Typography>

      <ListItem button component={OutdoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Outdoor Cameras
          </Typography>
      </ListItem>
      <ListItem button component={CompareOutdoorCams}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Compare Cameras
          </Typography>
      </ListItem>

      <ListItem button component={In9020}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-9020 Full HD
          </Typography>
      </ListItem>

      <ListItem button component={In9010}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-9010 Full HD
          </Typography>
      </ListItem>

      <ListItem button component={In9008}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-9008 Full HD
          </Typography>
      </ListItem>

      <ListItem button component={In7011}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-7011 HD
          </Typography>
      </ListItem>

      <ListItem button component={In5907}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-5907 HD
          </Typography>
      </ListItem>

      <ListItem button component={In5905}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-5905 HD
          </Typography>
      </ListItem>

      <ListItem button component={In2905}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-2905 v2
          </Typography>
      </ListItem>

      <ListItem button component={In2908}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-2908
          </Typography>
      </ListItem>

      <ListItem button component={In4010}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-4010 v2
          </Typography>
      </ListItem>

      <ListItem button component={In4011}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ IN-4011
          </Typography>
      </ListItem>
    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)