import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Location } from '@reach/router'

import { SimpleImg } from 'react-simple-img'

import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Fab from '@material-ui/core/Fab'
import SearchButton from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import theme from '../../templates/theme'

import MainNav from './Side_Navigation/index'
import AdvancedMenu from './advanced-nav'
import RemoteMenu from './remote-nav'
import MotionMenu from './motion-nav'
import InstallationMenu from './installation-nav'
import ProductsMenu from './products-nav'


import Conditional from './Side_Navigation/conditional-render.js'


import MenuButton from './mobile-nav'
import presets from '../../utils/presets'

import LogoPlaceholder from '../../assets/images/Index/logo_162x51_blur.jpg'
import Logo from '../../assets/images/Index/logo_850x268.jpg'

require('../../assets/styles/markdown.css')

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: 1160,
    minHeight: '100 vh',
    position: 'relative',
    backgroundColor: '#fafafa',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
  },
  logobox: {
    maxHeight: 51,
    maxWidth: 162,
    flex: 'none',
    margin: '7px 5px 5px -15px',
    boxShadow: '1px 1px 1px #888888',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  floatbar: {
    width: '100%',
    maxWidth: 950,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  navMenuHide: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    color: '#000',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 1,
    width: drawerWidth,
    height: `100vh`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingRight: 17,
    WebkitOverflowScrolling: 'touch',
    MsOverflowStyle: 'none',
    overflow: '-moz-scrollbars-none',
    backgroundColor: '#2f78f9',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: 860,
    display: `block`,
    height: `100vh`,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, .1)',
    backgroundColor: '#fdfdfd',
    overflowY: 'scroll',
    paddingRight: 17,
    WebkitOverflowScrolling: 'touch',
    MsOverflowStyle: 'none',
    overflow: '-moz-scrollbars-none',
  },
  fabcontainer: {
    display: 'flex',
    justifyContent: 'center',
    position: `fixed`,
    zIndex: 1000,
    right: 25,
    bottom: 90,
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
  },
  fab: {
      background: presets.brand,
      marginLeft: '5px',
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      position: 'absolute',
      right: theme.spacing(8),
  },
  search: {
      fontSize: 20
  },
}));

const SearchAssistant = React.forwardRef((props, ref) => <Link to="/Search/Results/"  role="button" {...props} ref={ref} />)

export default function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <React.Fragment>
        <div className={classes.toolbar} />
            <MainNav />
      </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logobox}>
              <SimpleImg
                className={classes.logo}
                alt="INSTAR Deutschland GmbH"
                animationDuration={1}
                placeholder={LogoPlaceholder}
                src={Logo}
                height={51}
                width={162}
              />
            </div>
            <div className={classes.floatbar}>
                <div className={classes.navMenuHide}>
                    <ProductsMenu />
                    <InstallationMenu />
                    <MotionMenu />
                    <RemoteMenu />
                    <AdvancedMenu />
                </div>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                <MenuIcon color="action" />
              </IconButton>
            </div>
        </Toolbar>
      </AppBar>
      <Location>
      {({ location }) => (
        <Conditional if={location.pathname.match(/^((?!\/Search\/Results\/).)*$/gm)}>
            <nav className={classes.drawer} aria-label="Site Navigation">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden mdUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
        </Conditional>
          )}
      </Location>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>{props.children}</div>
            <div className={classes.fabcontainer}>
              <Fab
                  component={SearchAssistant}
                  style={{textDecoration: 'none', color: 'white'}}
                  color="primary"
                  aria-label="search"
                  className={classes.fab}>
                  <SearchButton className={classes.search} />
              </Fab>
              <MenuButton />
            </div>
        </ThemeProvider>
      </main>
      <script src="/fr/dot.js"></script>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
