import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const ButtonLink01 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Point_to_Point/"  role="button" {...props} ref={ref} />)
const ButtonLink02 = React.forwardRef((props, ref) => <Link to="/Internet_Access/The_DDNS_Service/"  role="button" {...props} ref={ref} />)
const ButtonLink03 = React.forwardRef((props, ref) => <Link to="/Internet_Access/The_DDNS_Service/Test_DDNS_Address/"  role="button" {...props} ref={ref} />)
const ButtonLink04 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/"  role="button" {...props} ref={ref} />)
const ButtonLink05 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Router_Access/"  role="button" {...props} ref={ref} />)
const ButtonLink06 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/AVM_Fritzbox/"  role="button" {...props} ref={ref} />)
const ButtonLink07 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Telekom_Speedport/"  role="button" {...props} ref={ref} />)
const ButtonLink08 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/D-Link/"  role="button" {...props} ref={ref} />)
const ButtonLink09 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Netgear/"  role="button" {...props} ref={ref} />)
const ButtonLink10 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Vodafone_Easybox/"  role="button" {...props} ref={ref} />)
const ButtonLink11 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Pirelli/"  role="button" {...props} ref={ref} />)
const ButtonLink12 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Digitalisierungsbox_Smart/"  role="button" {...props} ref={ref} />)
const ButtonLink13 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Asus/"  role="button" {...props} ref={ref} />)
const ButtonLink14 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/Linksys/"  role="button" {...props} ref={ref} />)
const ButtonLink15 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Port_Forwarding/TP-Link/"  role="button" {...props} ref={ref} />)
const ButtonLink16 = React.forwardRef((props, ref) => <Link to="/Internet_Access/Mobile_Access/"  role="button" {...props} ref={ref} />)
const ButtonLink17 = React.forwardRef((props, ref) => <Link to="/Internet_Access/DDNS_Provider/"  role="button" {...props} ref={ref} />)
const ButtonLink18 = React.forwardRef((props, ref) => <Link to="/Internet_Access/DDNS_Provider/NoIP/"  role="button" {...props} ref={ref} />)
const ButtonLink19 = React.forwardRef((props, ref) => <Link to="/Internet_Access/DDNS_Provider/Selfhost/"  role="button" {...props} ref={ref} />)
const ButtonLink20 = React.forwardRef((props, ref) => <Link to="/Internet_Access/DDNS_Provider/DDNSS/"  role="button" {...props} ref={ref} />)

function InternetNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Accès à distance
        </Typography>
      <ListItem button component={ButtonLink01}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Service P2P
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink02}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Service DDNS
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink03}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Tests DDNS
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink04}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          Port Forwarding
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink05}>
        <Typography variant="subtitle1" className={classes.navsubtitleindent}>
          ▪ Accès au routeur
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink06}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ AVM Fritzbox
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink07}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Telekom Speedport
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink08}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ D-Link
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink09}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Netgear
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink10}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Vodafone Easybox
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink11}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Pirelli Router
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink12}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Digitalisierungsbox Smart
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink13}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Asus
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink14}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Linksys
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink15}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ TP-Link
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink16}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Accès mobile
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink17}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Fournisseur DDNS tiers
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink18}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ NoIP
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink19}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Selfhost
          </Typography>
      </ListItem>
      <ListItem button component={ButtonLink20}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ DDNSS
          </Typography>
      </ListItem>
    </List>
  );
}

InternetNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

// export default withRoot(withStyles(styles)(InternetNav))

export default withStyles(styles, { withTheme: true })(InternetNav)