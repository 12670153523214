import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  navtitle: {
    color: theme.palette.background.paper,
    margin: '15px 0  10px 20px',
    boxShadow: '0 1px 2px -2px white',
  },
  navsubtitle: {
    color: theme.palette.background.paper,
  },
  navsubtitleindent: {
    color: theme.palette.background.paper,
    marginLeft: 15,
  },
  navsubtitlesecindent: {
    color: theme.palette.background.paper,
    marginLeft: 25,
  },
  navbutton: {
    color: theme.palette.background.paper,
  }
})

const CGICommands = React.forwardRef((props, ref) => <Link to="/Advanced_User/CGI_Commands/"  role="button" {...props} ref={ref} />)
const CGICommands1080p = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/"  role="button" {...props} ref={ref} />)
const CGICommands1080pAlarm = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Alarm_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands1080pAlarmActions = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Alarm_Menu/Actions/"  role="button" {...props} ref={ref} />)
const CGICommands1080pAlarmAS = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Alarm_Menu/Alarm_Server/"  role="button" {...props} ref={ref} />)
const CGICommands1080pAlarmAreas = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Alarm_Menu/Areas/"  role="button" {...props} ref={ref} />)
const CGICommands1080pAlarmPush = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Alarm_Menu/Push_Service/"  role="button" {...props} ref={ref} />)
const CGICommands1080pAlarmSchedule = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Alarm_Menu/Schedule/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeatures = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesEmail = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/Email/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesFTP = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/FTP/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesIR = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/IR_Nightvision/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeatureMQTT = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/MQTT/"  role="button" {...props} ref={ref} />)
// const CGICommands1080pFeaturesRec = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/Manual_Recording/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesPTZ = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/PTZ/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesPTZTour = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/PTZ-Tour/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesSD = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/SD_Card/"  role="button" {...props} ref={ref} />)
const CGICommands1080pFeaturesStatus = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Features_Menu/Status_LEDs/"  role="button" {...props} ref={ref} />)
const CGICommands1080pMulti = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Multimedia_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands1080pMultiAudio = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Multimedia_Menu/Audio/"  role="button" {...props} ref={ref} />)
const CGICommands1080pMultiImage = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Multimedia_Menu/Image/"  role="button" {...props} ref={ref} />)
const CGICommands1080pMultiVideo = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Multimedia_Menu/Video/"  role="button" {...props} ref={ref} />)
const CGICommands1080pMultiOverlays = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Multimedia_Menu/Overlays/"  role="button" {...props} ref={ref} />)
const CGICommands1080pMultiPrivacy = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Multimedia_Menu/Privacy_Mask/"  role="button" {...props} ref={ref} />)
const CGICommands1080pNet = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Network_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands1080pNetDDNS = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Network_Menu/Remote_Access/"  role="button" {...props} ref={ref} />)
const CGICommands1080pNetConfig = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Network_Menu/IP_Configuration/"  role="button" {...props} ref={ref} />)
const CGICommands1080pNetUPnP = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Network_Menu/UPnP/"  role="button" {...props} ref={ref} />)
const CGICommands1080pNetWiFi = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Network_Menu/WiFi/"  role="button" {...props} ref={ref} />)
const CGICommands1080pNetONVIF = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Network_Menu/ONVIF/"  role="button" {...props} ref={ref} />)
// const CGICommands1080pRec = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Recording_Menu/"  role="button" {...props} ref={ref} />)
// const CGICommands1080pRecPhotoseries = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Recording_Menu/Photoseries/"  role="button" {...props} ref={ref} />)
// const CGICommands1080pRecVideo = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/Recording_Menu/Video/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystem = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemDate = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/Date_Time/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemLanguage = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/Language/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemInfo = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/Overview/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemReboot = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/Reboot/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemLog = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/System_Log/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemReset = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/Reset/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemUpdate = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/Update/"  role="button" {...props} ref={ref} />)
const CGICommands1080pSystemUser = React.forwardRef((props, ref) => <Link to="/1080p_Series_CGI_List/System_Menu/User/"  role="button" {...props} ref={ref} />)


function ProductNav(props) {
  const { classes } = props;
  return (
    <List component="nav">
      <Typography variant="h5" className={classes.navtitle}>
      Pour les développeurs
        </Typography>
      <ListItem button component={CGICommands}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
        Commandes CGI
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080p}>
        <Typography variant="subtitle1" className={classes.navsubtitle}>
          1080p Commandes CGI
          </Typography>
      </ListItem>

      <ListItem button component={CGICommands1080pAlarm}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Alarm Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pAlarmActions}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Actions
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pAlarmAS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Alarm Server
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pAlarmAreas}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Alarm Areas
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pAlarmPush}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Push Service
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pAlarmSchedule}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Schedule
          </Typography>
      </ListItem>


      <ListItem button component={CGICommands1080pMulti}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Multimedia Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pMultiAudio}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Audio
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pMultiImage}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Image
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pMultiVideo}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pMultiOverlays}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Image
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pMultiPrivacy}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video
          </Typography>
      </ListItem>


      <ListItem button component={CGICommands1080pNet}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Network Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pNetDDNS}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Remote Access
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pNetConfig}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IP Configuration
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pNetONVIF}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › ONVIF
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pNetUPnP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › UPnP
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pNetWiFi}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › WiFi
          </Typography>
      </ListItem>


      {/* <ListItem button component={CGICommands1080pRec}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Recording Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pRecPhotoseries}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Photoseries
          </Typography>
      </ListItem> */}
      {/* <ListItem button component={CGICommands1080pRecVideo}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Video
          </Typography>
      </ListItem> */}


      <ListItem button component={CGICommands1080pFeatures}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ Features Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeaturesEmail}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Email
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeaturesFTP}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › FTP
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeaturesIR}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › IR LEDs
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeatureMQTT}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › MQTT Broker
          </Typography>
      </ListItem>
      {/* <ListItem button component={CGICommands1080pFeaturesRec}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Manual Recording
          </Typography>
      </ListItem> */}
      <ListItem button component={CGICommands1080pFeaturesPTZ}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › PTZ
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeaturesPTZTour}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › PTZ Tour
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeaturesSD}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › SD Card
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pFeaturesStatus}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Status LEDs
          </Typography>
      </ListItem>


      <ListItem button component={CGICommands1080pSystem}>
        <Typography variant="caption" className={classes.navsubtitleindent}>
          ▪ System Menu
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemDate}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Date & Time
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemInfo}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Overview
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemLog}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › System Log
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemReset}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Reset
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemReboot}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Reboot
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemLanguage}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Language
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemUpdate}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › Update
          </Typography>
      </ListItem>
      <ListItem button component={CGICommands1080pSystemUser}>
        <Typography variant="caption" className={classes.navsubtitlesecindent}>
          › User Management
          </Typography>
      </ListItem>


    </List>
  );
}

ProductNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ProductNav)